/* Base sidebar styles */
:root {
  --sidebar-width-collapsed: 3.125rem;  /* 50px */
  --sidebar-width-expanded: 12.5rem;    /* 200px */
  --button-size: 2.5rem;                /* 40px */
}

.global-sidebar {
  width: var(--sidebar-width-collapsed);
  transition: width 0.2s;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1100;
  background: #fff;
  box-shadow: 2px 0 5px rgba(0,0,0,0.1);
  overflow: hidden;
}

.global-sidebar.expanded {
  width: var(--sidebar-width-expanded);
}

/* Logo styles */
.logo-container-sidebar {
  position: absolute;
  top: 2vh;
  width: var(--sidebar-width-collapsed);
  height: var(--sidebar-width-collapsed);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.sidebar-logo {
  width: var(--button-size);
  height: var(--button-size);
  object-fit: contain;
}

/* Common button styles */
.sidebar-button {
  background: none;
  border: none;
  color: #343a40;
  font-size: 1.5em;
  width: var(--button-size);
  height: var(--button-size);
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.global-sidebar.expanded .sidebar-button {
  left: 0.625rem;
  transform: none;
  width: calc(100% - 1.25rem);
  justify-content: flex-start;
  padding-left: 0.625rem;
}

.sidebar-button .button-label {
  display: none;
  margin-left: 0.625rem;
  font-size: 0.875rem;
}

.global-sidebar.expanded .sidebar-button .button-label {
  display: inline;
}

/* Positioned buttons */
/* Specific button positions - collapsed state */
.exports-modal {
  max-width: 900px;
}

.dashboard-button {
  top: 15vh;
}

.manage-access-button {
  top: 30vh;
}

.export-data-button {
  top: 35vh;
}

/* Specific button positions - expanded state */
.global-sidebar.expanded .dashboard-button {
  top: 15vh;
  width: calc(100% - 1.25rem);
}

.global-sidebar.expanded .manage-access-button {
  top: 30vh;
  width: calc(100% - 1.25rem);
}

.global-sidebar.expanded .export-data-button {
  top: 35vh;
  width: calc(100% - 1.25rem);
}

/* Bottom section buttons - collapsed state */
.admin-button {
  bottom: 20vh;
}

.account-button {
  bottom: 9vh;
}

.logout-button {
  bottom: 4vh;
}

/* Bottom section buttons - expanded state */
.global-sidebar.expanded .admin-button {
  bottom: 20vh;
  width: calc(100% - 1.25rem);
}

.global-sidebar.expanded .account-button {
  bottom: 9vh;
  width: calc(100% - 1.25rem);
}

.global-sidebar.expanded .logout-button {
  bottom: 4vh;
  width: calc(100% - 1.25rem);
}

/* Public view buttons - collapsed state */
.register-button {
  top: 15vh;
}

.login-button {
  top: 25vh;
}

/* Public view buttons - expanded state */
.global-sidebar.expanded .register-button {
  top: 15vh;
  width: calc(100% - 1.25rem);
}

.global-sidebar.expanded .login-button {
  top: 25vh;
  width: calc(100% - 1.25rem);
}

/* Lock button */
.lock-button {
  position: absolute;
  top: 2vh;
  right: 0.625rem;
  background: transparent;
  border: none;
  color: #343a40;
  transition: background-color 0.2s;
}

.global-sidebar.expanded .lock-button {
  visibility: visible;
  opacity: 1;
  width: 3.125rem;
}

.global-sidebar:not(.expanded) .lock-button {
  display: none;
}

/* Hover effects */
.sidebar-button:hover {
  background-color: #C8E6C9;
}

/* Contracted state styles */
.global-sidebar:not(.expanded) .sidebar-button {
  border: 1px solid #343a40;
  border-radius: 50%;
}

.manage-access-modal {
  min-width: 300px;
}

/* Dialog styles */
.manage-access-modal .p-dialog-header {
  background-color: #32AFC3;
}

.manage-access-modal .p-dialog-title {
  color: white;
  padding-left: 1.25rem;
}

.admin-button i {
  color: #C049DE;
}

/* Mobile Responsive Breakpoints */
@media screen and (max-width: 768px) {
  .sidebar-button {
    font-size: 1.25em;
  }

  .sidebar-button .button-label {
    font-size: 0.75rem;
  }

  .manage-access-modal {
    max-width: 600px;
  }

  .exports-modal {
    max-width: 600px;
  }
}

@media (max-width: 480px) {
  .manage-access-modal {
    max-width: 350px;
  }

  .exports-modal {
    max-width: 360px;
  }
}


@media screen and (max-height: 900px) {
  .export-data-button {
    top: 38vh;
  }

  .global-sidebar.expanded .export-data-button {
    top: 38vh;
  }

  .account-button {
    bottom: 11vh;
  }

  .global-sidebar.expanded .account-button {
    bottom: 11vh;
  }
}

@media screen and (max-height: 600px) {
  /* Adjust vertical positions for smaller screens */
  .dashboard-button, .register-button {
    top: 20vh;
  }

  .global-sidebar.expanded .dashboard-button, .register-button {
    top: 20vh;
  }

  .manage-access-button, .login-button {
    top: 30vh;
  }

  .global-sidebar.expanded .manage-access-button, .login-button {
    top: 30vh;
  }

  .export-data-button {
    top: 40vh;
  }

  .global-sidebar.expanded .export-data-button {
    top: 40vh;
  }

  .admin-button {
    bottom: 16vh;
  }

  .global-sidebar.expanded .admin-button {
    bottom: 16vh;
  }

  .account-button {
    bottom: 12vh;
  }

  .global-sidebar.expanded .account-button {
    bottom: 12vh;
  }

  .logout-button {
    bottom: 2vh;
  }

  .global-sidebar.expanded .logout-button {
    bottom: 2vh;
  }
}

@media screen and (max-height: 450px) {
  /* Adjust vertical positions for smaller screens */

  .manage-access-button, .login-button {
    top: 31vh;
  }

  .global-sidebar.expanded .manage-access-button, .login-button {
    top: 31vh;
  }

  .export-data-button {
    top: 42vh;
  }

  .global-sidebar.expanded .export-data-button {
    top: 42vh;
  }

  .account-button {
    bottom: 13vh;
  }

  .global-sidebar.expanded .account-button {
    bottom: 13vh;
  }
}

.create-badge-button {
  top: 40vh;
}

.global-sidebar.expanded .create-badge-button {
  top: 40vh;
  width: calc(100% - 1.25rem);
}

/* Update media queries for the new button */
@media screen and (max-height: 900px) {
  .create-badge-button {
    top: 43vh;
  }

  .global-sidebar.expanded .create-badge-button {
    top: 43vh;
  }
}

@media screen and (max-height: 600px) {
  .create-badge-button {
    top: 45vh;
  }

  .global-sidebar.expanded .create-badge-button {
    top: 45vh;
  }
}
