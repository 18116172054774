.platform-demo-container {
  width: 100%;
  position: relative;
  overflow: visible;
  padding: 24px;
}

.demo-content {
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 12px;
  padding: 32px;
  box-shadow: 
    0 0 20px 2px rgba(248, 161, 209, 0.5),
    0 0 40px 4px rgba(159, 95, 233, 0.3);
  animation: glow 15s ease-in-out infinite;
}

/* Override DemoScoreGraph styles for text colors */
.demo-content :global(.score-title),
.demo-content :global(.main-title),
.demo-content :global(.sub-title) {
  color: #1C2B40 !important;
}

.demo-content :global(.score-row:hover) {
  background: rgba(28, 43, 64, 0.05);
}

@keyframes glow {
  0% {
    box-shadow: 
      0 0 20px 2px rgba(248, 161, 209, 0.5),
      0 0 40px 4px rgba(159, 95, 233, 0.3);
  }
  50% {
    box-shadow: 
      0 0 30px 4px rgba(248, 161, 209, 0.6),
      0 0 50px 6px rgba(159, 95, 233, 0.4);
  }
  100% {
    box-shadow: 
      0 0 20px 2px rgba(248, 161, 209, 0.5),
      0 0 40px 4px rgba(159, 95, 233, 0.3);
  }
}

@media (max-width: 768px) {
  .platform-demo-container {
    padding: 16px;
  }

  .demo-content {
    padding: 24px 16px;
  }
} 