.account-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin: 1rem;
    max-width: 800px;
    width: 100%;
}

.account-header {
    margin-bottom: 2rem;
    margin-left: 1rem;
}

.account-header h2 {
    font-size: 1.5rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 0.5rem;
}

.account-subtitle {
    color: #666;
    margin: 0;
}

.account-options {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-left: 1rem;
}

.account-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    transition: background-color 0.2s;
}

.account-option:hover {
    background-color: #f9f9f9;
}

.option-content h3 {
    font-size: 1.1rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 0.25rem;
}

.option-content p {
    color: #666;
    margin: 0;
    font-size: 0.9rem;
}

/* Password Change Form Styles */
.password-change-form {
    max-width: 500px;
    margin-left: 1rem;
}

.confirm-password-change-button {
    background-color: #2CB392;
    color: #ffffff;
    min-width: 200px;
}



.form-header {
    margin-bottom: 2rem;
}

.form-header h3 {
    font-size: 1.25rem;
    color: #1a1a1a;
    margin: 0;
    margin-bottom: 0.5rem;
}

.form-header p {
    color: #666;
    margin: 0;
    font-size: 0.9rem;
}

.password-change-form .p-field {
    margin-bottom: 1.5rem;
}

.password-change-form label {
    display: block;
    margin-bottom: 0.5rem;
    color: #1a1a1a;
    font-weight: 500;
    font-size: 0.9rem;
}

.password-change-form .p-password,
.password-change-form .p-password input {
    width: 100%;
}

.password-change-form .p-password input {
    padding: 0.75rem;
    font-size: 0.9rem;
}

.password-change-form .p-password input:focus {
    box-shadow: none;
    border-color: #2196F3;
}

.password-change-form .p-password.p-invalid input {
    border-color: #f44336;
}

.p-error {
    color: #f44336;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    display: block;
}

.p-help {
    color: #666;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    display: block;
}

.password-actions {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 2rem;
}

.password-actions .p-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

/* Password Strength Meter */
.p-password-meter {
    margin-top: 0.5rem;
}

/* Toast Customization */
.p-toast {
    opacity: 1;
}

.p-toast .p-toast-message {
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.p-toast .p-toast-message-success {
    background-color: #e8f5e9;
    border-left: 6px solid #4caf50;
}

.p-toast .p-toast-message-error {
    background-color: #ffebee;
    border-left: 6px solid #f44336;
}

/* Responsive Design */
@media (max-width: 768px) {
    .account-card {
        margin: 0.5rem;
        padding: 1rem;
    }

    .account-option {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        padding: 1rem;
    }

    .account-option .p-button {
        width: 100%;
    }

    .password-actions {
        flex-direction: column;
    }

    .password-actions .p-button {
        width: 100%;
    }

    .password-change-form {
        margin-left: 0;
    }
} 