.security-page {
  position: relative;
  min-height: 100vh;
}

/* Security Hero Section */
.security-page .security-hero {
  background: linear-gradient(
    135deg,
    #32AFC3 0%,
    #2ee0ec 25%,
    #7db4fc 50%,
    #6776fa 75%,
    #8053fc 100%
  );
  background-size: 400% 400%;
  animation: security-gradient-flow 15s ease infinite;
  width: 100%;
  position: relative;
  z-index: 1;
  height: 66vh;
  min-height: 600px;
  display: flex;
  flex-direction: column;
}

.security-page .security-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 24px 120px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1;
}

.security-page .security-hero-text {
  max-width: 800px;
  text-align: center;
}

.security-page .security-tag {
  display: inline-block;
  padding: 8px 16px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  color: white;
  font-size: 0.9rem;
  font-weight: 500;
  margin-bottom: 32px;
}

/* Hero Title Styles */
.security-page .security-title {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 24px;
}

.security-page .gradient-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.security-page .security-gradient-text {
  font-size: 3.5rem;
  font-weight: 600;
  background: linear-gradient(
    45deg,
    #ffffff 0%,
    #90caf9 25%,
    #ffffff 50%,
    #90caf9 75%,
    #ffffff 100%
  );
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: security-text-gradient-flow 15s ease infinite;
  margin: 0;
}

.security-page .security-title h1 {
  font-size: 3.5rem;
  color: white;
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
}

.security-page .security-description {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 48px 0;
  line-height: 1.6;
}

/* CTA Buttons */
.security-page .security-cta {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.security-page .security-cta-button {
  background-color: white;
  color: #323232;
  border: 2px solid white;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.security-page .security-cta-button:hover {
  background-color: transparent;
  color: white;
}

.security-page .security-secondary-button {
  background-color: transparent;
  color: white;
  border: 2px solid white;
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.security-page .security-secondary-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Security Benefits Section */
.security-page .security-benefits-section {
  position: relative;
  z-index: 2;
  padding: 0 5rem;
  margin-top: -120px;
  background-color: #f8f9fa;
}

.security-page .security-benefits-grid {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 380px));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
}

.security-page .security-benefit-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: -20px;
}

.security-page .security-benefit-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.security-page .security-benefit-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 1rem;
  object-fit: contain;
}

.security-page .security-benefit-card h3 {
  font-size: 1.25rem;
  color: #323232;
  margin: 0 0 1rem 0;
  font-weight: 600;
  line-height: 1.4;
}

.security-page .security-benefit-card p {
  font-size: 1rem;
  color: #666;
  margin: 0;
  line-height: 1.6;
}

/* Animations */
@keyframes security-gradient-flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes security-text-gradient-flow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Media Queries */
@media (max-width: 1200px) {
  .security-page .security-benefits-section {
    padding: 0 2rem;
  }
  
  .security-page .security-benefits-grid {
    grid-template-columns: repeat(2, minmax(280px, 340px));
    gap: 2rem;
  }

  .security-page .security-hero-text {
    max-width: 700px;
  }

  .security-page .security-gradient-text,
  .security-page .security-title h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .security-page .security-hero {
    height: auto;
    min-height: 80vh;
  }

  .security-page .security-hero-content {
    padding: 32px 16px 80px;
  }

  .security-page .security-gradient-text,
  .security-page .security-title h1 {
    font-size: 2.5rem;
  }

  .security-page .security-title {
    flex-direction: column;
    gap: 8px;
  }

  .security-page .security-cta {
    flex-direction: column;
    width: 100%;
  }

  .security-page .security-cta-button,
  .security-page .security-secondary-button {
    width: 100%;
  }

  .security-page .security-benefits-section {
    padding: 0 1.5rem;
    margin-top: -80px;
  }

  .security-page .security-benefits-grid {
    grid-template-columns: minmax(280px, 400px);
    gap: 2rem;
  }

  .security-page .security-benefit-card {
    padding: 1rem;
  }

  .security-page .security-benefit-card h3 {
    font-size: 1.2rem;
  }

  .security-page .timeline-container {
    width: calc(100% - 1.5rem);
    margin: 0 0.75rem;
    padding: 0;
  }

  .security-page .timeline-main-title {
    font-size: 2rem;
    margin-bottom: 48px;
  }

  .security-page .customized-timeline {
    padding-left: 0;
  }

  .security-page .customized-timeline .p-timeline-event {
    margin-bottom: 4rem;
    padding-left: 0;
    margin-left: -16px;
  }

  .security-page .customized-timeline .p-timeline-event-separator {
    padding: 0;
    margin-right: 8px;
    left: -42px;
  }

  .security-page .customized-timeline .p-timeline-event-content {
    padding-left: 16px;
    max-width: calc(100% - 24px);
    margin-left: 0;
  }

  .security-page .custom-marker {
    width: 24px;
    height: 24px;
  }

  .security-page .custom-marker i {
    font-size: 0.8rem;
  }

  .security-page .customized-timeline .p-timeline-event-connector {
    top: 24px;
    width: 1.5px;
  }

  .security-page .timeline-event-content p,
  .security-page .timeline-bullet-points li {
    font-size: 0.95rem;
    line-height: 1.5;
  }

  .security-page .timeline-bullet-points li {
    padding-left: 20px;
  }

  .security-page .timeline-bullet-points li::before {
    width: 6px;
    height: 6px;
    top: 7px;
  }
}

@media (max-width: 480px) {
  .security-page .security-hero {
    padding: 40px 0;
  }

  .security-page .security-gradient-text,
  .security-page .security-title h1 {
    font-size: 2rem;
  }

  .security-page .security-description {
    font-size: 1rem;
    margin-bottom: 32px;
  }

  .security-page .timeline-container {
    width: calc(100% - 1rem);
    margin: 0 0.5rem;
  }

  .security-page .customized-timeline .p-timeline-event {
    margin-left: -20px;
  }

  .security-page .customized-timeline .p-timeline-event-content {
    padding-left: 12px;
    max-width: calc(100% - 16px);
  }

  .security-page .timeline-event-content {
    padding: 12px;
  }
}

/* Timeline Section */
.security-page .timeline-section {
  padding: 120px 0 4rem 0;
  background-color: #f8f9fa;
  display: flex;
  justify-content: center;
}

.security-page .timeline-container {
  width: calc(100% - 8rem);
  margin: 0 4rem;
  padding: 0;
}

.security-page .timeline-main-title {
  font-size: 2.8rem;
  font-weight: 600;
  color: #323232;
  text-align: center;
  margin-bottom: 24px;
  background: linear-gradient(135deg, #4cbdff 0%, #34d4fc 50%, #8053fc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.security-page .timeline-subtitle {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  max-width: 800px;
  margin: 0 auto 64px;
  line-height: 1.6;
}

.security-page .timeline-wrapper {
  position: relative;
  padding: 20px 0;
}

/* Add a continuous background line */
.security-page .timeline-wrapper::before {
  content: '';
  position: absolute;
  top: 20px;
  bottom: 17%;
  left: 35%;
  width: 2px;
  background: linear-gradient(180deg, #278bc5 0%, #3d5dc7 100%);
  transform: translateX(-50%);
  z-index: 1;
}

/* Hide PrimeReact's default connector */
.security-page .customized-timeline .p-timeline-event-connector {
  display: none;
}

/* Ensure markers appear above the line */
.security-page .custom-marker {
  position: relative;
  z-index: 2;
  background: linear-gradient(180deg, #278bc5 0%, #3d5dc7 100%);
}

/* Timeline Event Styles */
.security-page .timeline-event-title {
  text-align: right;
  padding-right: 32px;
}

.security-page .timeline-event-title h2 {
  font-size: 1.4rem;
  color: #323232;
  margin: 0;
  font-weight: 600;
  line-height: 1.4;
}

.security-page .timeline-event-content {
  background: white;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-left: 32px;
}

.security-page .timeline-event-content p {
  font-size: 1.1rem;
  color: #666;
  margin: 0 0 16px 0;
  line-height: 1.6;
}

.security-page .timeline-event-content p:last-of-type {
  margin-bottom: 8px;
}

.security-page .timeline-bullet-points {
  list-style: none;
  padding: 0;
  margin: 0 0 16px 0;
}

.security-page .timeline-bullet-points li {
  position: relative;
  padding-left: 24px;
  margin-bottom: 12px;
  color: #666;
  font-size: 1.1rem;
  line-height: 1.5;
}

.security-page .timeline-bullet-points li::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  width: 8px;
  height: 8px;
  background: linear-gradient(135deg, #4cbdff 0%, #8053fc 100%);
  border-radius: 50%;
}

.security-page .timeline-bullet-points li:last-child {
  margin-bottom: 0;
}

.security-page .timeline-note {
  margin-top: 16px;
  padding: 12px 16px;
  background: rgba(76, 189, 255, 0.1);
  border-left: 3px solid #4cbdff;
  border-radius: 4px;
  color: #666;
  font-size: 1rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 8px;
}

.security-page .timeline-note i {
  color: #4cbdff;
  font-size: 1.1rem;
}

.security-page .timeline-event-content .highlight {
  color: #4cbdff;
  font-weight: 500;
}

.security-page .timeline-event-content a {
  color: #4cbdff;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.security-page .timeline-event-content a:hover {
  color: #8053fc;
  text-decoration: underline;
}

.security-page .timeline-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
}

.security-page .timeline-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-top: 0;
}

/* Timeline Marker and Line Styles */
.security-page .custom-marker {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #278bc5 0%, #3d5dc7 100%);
  border-radius: 50%;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.security-page .custom-marker i {
  color: white;
  font-size: 1rem;
}

/* PrimeReact Timeline Customization */
.security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 6rem;
}

.security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event-opposite {
  flex: 1;
  padding-right: 64px;
  text-align: right;
  max-width: 30%;
}

.security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event-content {
  flex: 2;
  padding-left: 64px;
  max-width: 60%;
}

.security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event-separator {
  flex: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 0 1rem;
}

.security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event-connector {
  position: absolute;
  width: 2px;
  background: linear-gradient(180deg, #278bc5 0%, #3d5dc7 100%);
  left: 50%;
  transform: translateX(-50%);
  top: 50px;
}

.security-page .timeline-event-content-title {
  display: none;
  font-size: 1.4rem;
  color: #323232;
  margin: 0 0 16px 0;
  font-weight: 600;
  line-height: 1.4;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 16px;
}

/* Media Queries */
@media (max-width: 1024px) {
  .security-page .timeline-container {
    width: calc(100% - 4rem);
    margin: 0 2rem;
  }

  .security-page .timeline-main-title {
    font-size: 2.4rem;
  }

  .security-page .timeline-event {
    grid-template-columns: 160px 1fr;
    gap: 32px;
  }

  .security-page .timeline-event-title {
    padding-right: 24px;
  }
}

@media (max-width: 850px) {
  .security-page .timeline-wrapper::before {
    left: 35.5%;
  }
}

@media (max-width: 768px) {
  .security-page .timeline-section {
    padding: 80px 0;
  }

  .security-page .timeline-container {
    width: calc(100% - 1rem);
    margin: 0 0.5rem;
  }

  .security-page .timeline-main-title {
    font-size: 2rem;
    margin-bottom: 48px;
  }

  .security-page .timeline-event {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .security-page .timeline-event-title {
    text-align: left;
    padding-right: 0;
    padding-left: 0;
  }

  .security-page .timeline-event-title h2 {
    font-size: 1.3rem;
  }

  .security-page .timeline-event-content {
    margin-left: 0;
    padding: 16px;
    min-width: 350px;
  }

  .security-page .timeline-event-content p,
  .security-page .timeline-bullet-points li {
    font-size: 1rem;
  }

  .security-page .timeline-note {
    font-size: 0.9rem;
    padding: 10px 14px;
  }

  .security-page .custom-marker {
    width: 28px;
    height: 28px;
  }

  .security-page .custom-marker i {
    font-size: 0.9rem;
  }

  .security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event {
    margin-bottom: 4rem;
    padding-left: 0;
    margin-left: -16px;
  }

  .security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event-separator {
    padding: 0;
    margin-right: 8px;
    position: absolute;
    left: 20px;
  }

  .security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event-content {
    padding-left: 60px;
    max-width: calc(100% - 40px);
    margin-left: 0;
  }

  .security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event-opposite {
    display: none;
  }

  .security-page .timeline-wrapper::before {
    left: 18px;
    z-index: 0;
  }
}

@media (max-width: 550px) {
  .security-page .timeline-wrapper::before {
    left: 20px;
    z-index: 0;
  }
}

@media (max-width: 480px) {
  .security-page .security-cta-section {
    padding: 80px 24px;
  }

  .security-page .security-cta-section h2 {
    font-size: 2.5rem;
  }

  .security-page .security-cta-section p {
    font-size: 1.2rem;
    margin-bottom: 32px;
  }

  .security-page .security-cta-buttons {
    flex-direction: column;
    width: 100%;
  }

  .security-page .security-cta-section .security-cta-button,
  .security-page .security-cta-section .security-secondary-button {
    width: 100%;
    justify-content: center;
    padding: 14px 24px;
    font-size: 1rem;
  }

  .security-page .timeline-wrapper::before {
    left: 14px; /* Further adjust for very small screens */
  }

  .security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event {
    margin-left: -20px;
  }

  .security-page .security-timeline-section .security-timeline.customized-timeline .p-timeline-event-content {
    padding-left: 48px;
    max-width: calc(100% - 32px);
  }
}

/* CTA Section */
.security-page .security-cta-section {
  background: linear-gradient(to right, rgba(243, 238, 255, 0.5), rgba(230, 238, 255, 0.5));
  padding: 120px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.security-page .security-cta-container {
  max-width: 800px;
  margin: 0 auto;
}

.security-page .security-cta-section h2 {
  font-size: 3.5rem;
  font-weight: 600;
  color: #323232;
  margin: 0 0 16px 0;
  line-height: 1.2;
}

.security-page .security-cta-section p {
  font-size: 1.4rem;
  color: #323232;
  margin: 0 0 48px 0;
  line-height: 1.6;
}

.security-page .security-cta-buttons {
  display: flex;
  gap: 16px;
  justify-content: center;
  align-items: center;
}

.security-page .security-cta-section .security-cta-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #323232;
  color: white;
  border: 2px solid #323232;
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.security-page .security-cta-section .security-cta-button:hover {
  background-color: transparent;
  color: #323232;
}

.security-page .security-cta-section .security-secondary-button {
  background-color: transparent;
  color: #323232;
  border: 2px solid #323232;
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.security-page .security-cta-section .security-secondary-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
  .security-page .security-cta-section {
    padding: 80px 24px;
  }

  .security-page .security-cta-section h2 {
    font-size: 2.5rem;
  }

  .security-page .security-cta-section p {
    font-size: 1.2rem;
    margin-bottom: 32px;
  }

  .security-page .security-cta-buttons {
    flex-direction: column;
    width: 100%;
  }

  .security-page .security-cta-section .security-cta-button,
  .security-page .security-cta-section .security-secondary-button {
    width: 100%;
    justify-content: center;
    padding: 14px 24px;
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .security-page .security-cta-section h2 {
    font-size: 2rem;
  }

  .security-page .security-cta-section p {
    font-size: 1.1rem;
  }
}
