.sidebar-custom {
    min-width: 300px; /* Minimum width of 300px */
    width: 60%; /* Ideal width of 60% */
    max-width: 100%; /* Ensure it doesn't exceed the viewport width */
  }

.filesdetail_chaptername {
  margin-left: 20px;
  font-weight: bold;
  margin-top: 20px;

}

.filesdetail_attribute_key {
  margin-left: 30px;
}

.file-not-audited-container {
  /* Your styles here */
}

.file-not-audited-title {
  padding-top: 20px;
  margin-left: 20px;
}

.file-not-audited-text {
  /* Your styles here */
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.file-not-audited-text .p-icon {
  margin-left: 5px;
}
