.audit-scores-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 0.5rem;
    padding: 0.75rem;
    border-radius: 4px;
    width: 100%;
}

.audit-score-bar-container {
    width: 100%;
    min-width: 0;
}

.audit-score-bar {
    width: 100%;
    height: 6px;
    background-color: #e0e0e0;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.audit-score-fill {
    height: 100%;
    transition: width 0.3s ease-in-out;
    border-radius: 3px;
}

/* Hide the entire grid on smaller screens instead of changing layout */
@media (max-width: 576px) {
    .audit-scores-grid {
        display: none;
    }
} 