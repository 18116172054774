/* NavigationMobile.css */
.navigation-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.mobile-nav-end {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.free-trial-button {
    background-color: #32AFC3;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.free-trial-button:hover {
    background-color: #2B95A6;
}

.mobile-menu-button {
    background: none !important;
    border: none !important;
    padding: 0.5rem !important;
    font-size: 1.5rem !important;
    color: #323232 !important;
}

.mobile-menu-button .p-button-icon {
    font-size: 1.5rem !important;
}

.mobile-menu-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 1rem;
}

.close-menu {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
}

.mobile-menu {
    padding: 2rem;
}

.mobile-menu-item {
    margin-bottom: 1.5rem;
}

.mobile-menu-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.mobile-menu-item-header h2 {
    font-size: 1.5rem;
    margin: 0;
}

.mobile-submenu-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
}

.mobile-submenu-header h2 {
    font-size: 1.5rem;
    margin: 0;
}

.mobile-submenu-divider {
    height: 1px;
    background-color: #e0e0e0;
    margin-bottom: 1.5rem;
}

.mobile-submenu-item {
    display: block;
    text-decoration: none;
    color: #323232;
    margin-bottom: 1.5rem;
}

.mobile-submenu-item h3 {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

.mobile-submenu-item p {
    font-size: 0.9rem;
    color: #8D8D8D;
    margin-bottom: 0.5rem;
}

.mobile-submenu-link {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: #32AFC3;
    font-size: 0.9rem;
    font-weight: bold;
}

.mobile-login-link,
.mobile-free-trial-link {
    display: block;
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
    text-decoration: none;
    font-weight: bold;
}

.mobile-login-link {
    color: #323232;
    border: 1px solid #323232;
}

.mobile-free-trial-link {
    background-color: #32AFC3;
    color: white;
}

.p-sidebar {
    padding-left: 10px;
}

.mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #e0e0e0;
}

.mobile-menu-logo {
    font-weight: bold;
    font-size: 1.5rem;
    color: #32AFC3;
}

.mobile-menu-close {
    background: none !important;
    border: none !important;
    font-size: 1.5rem !important;
    color: #323232 !important;
    padding: 0.5rem !important;
}

.mobile-submenu-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    cursor: pointer;
    margin-bottom: 1rem;
    padding: 1rem;
}

.mobile-submenu-header i {
    font-size: 1.5rem;
}

.mobile-submenu-header h2 {
    font-size: 1.5rem;
    margin: 0;
}

@media (max-width: 1000px) {
    .free-trial-button {
        width: 142px;
        padding: 9px 15px 9px 15px;
    }
    
}

@media (max-width: 768px) {
    .mobile-menu-button {
        width: 100%;
    }
}

