/* Terms.css */
.terms-page {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #323232;
  }
  
  .terms-header {
    background-color: #F9F9F9;
    padding: 2rem;
    text-align: center;
  }
  
  .terms-logo {
    color: #32AFC3;
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  
  .terms-title {
    color: #323232;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .terms-date {
    font-size: 0.9rem;
  }

  .terms-url {
    color: #C049DE;
    text-decoration: underline;
  }
  
  .terms-container {
    background-color: white;
    color: #32AFC3;
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .terms-section-title {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  
  .terms-subsection-title {
    font-weight: bold;
    font-size: 1.2rem;
    margin-top: 1.5rem;
    margin-bottom: 0.75rem;
  }
  
  .terms-paragraph {
    margin-bottom: 1rem;
  }
  
  .terms-list {
    list-style-type: disc;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }

  .responsive-image {
    max-width: 100%;
    height: auto;
    width: 900px; /* Or any other max width you prefer */
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .terms-header {
      padding: 1rem;
    }
  
    .terms-title {
      font-size: 1.5rem;
    }
  
    .terms-container {
      padding: 1rem;
    }
  
    .terms-section-title {
      font-size: 1.3rem;
    }
  
    .terms-subsection-title {
      font-size: 1.1rem;
    }

    .responsive-image {
      width: 100%;
    }
  }
  
  @media (max-width: 480px) {
    .terms-logo {
      font-size: 0.9rem;
    }
  
    .terms-title {
      font-size: 1.3rem;
    }
  
    .terms-date {
      font-size: 0.8rem;
    }
  
    .terms-section-title {
      font-size: 1.2rem;
    }
  
    .terms-subsection-title {
      font-size: 1rem;
    }
  }