.platform-page {
  position: relative;
  min-height: 100vh;
}

/* Platform Hero Section */
.platform-page .platform-hero {
  background-color: #1C2B40;
  padding: 80px 0 0 0;
  width: 100%;
  position: relative;
  z-index: 2;
}

.platform-page .platform-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 0 8rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.platform-page .platform-hero-text {
  max-width: 800px;
  margin-bottom: 48px;
}

.platform-page .platform-hero-text h3 {
  font-size: 1.2rem;
  color: white;
  margin: 0;
  font-weight: 400;
}

/* Hero Title Styles */
.platform-page .hero-title {
  display: flex;
  gap: 0;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 16px 0;
}

.platform-page .gradient-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  white-space: nowrap;
}

.platform-page .hero-gradient-text {
  font-size: 3.5rem;
  font-weight: 600;
  background: linear-gradient(
    45deg,
    #F8A1D1 0%,
    #9F5FE9 25%,
    #F8A1D1 50%,
    #9F5FE9 75%,
    #F8A1D1 100%
  );
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hero-gradient-flow 15s ease infinite;
  display: inline;
  white-space: nowrap;
}

.platform-page .hero-title h1 {
  font-size: 3.5rem;
  color: white;
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
  display: inline;
  margin-left: 12px;
  white-space: nowrap;
}

.platform-page .platform-hero-text .hero-description-platform {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 8px 0 0 0;
  line-height: 1.6;
  font-weight: 400;
}

/* Hero CTA */
.platform-page .hero-cta {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 48px;
  margin-bottom: 8rem;
}

.platform-page .hero-cta .cta-primary-button {
  box-sizing: border-box;
  min-width: 200px;
  padding: 14px 28px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 6px;
  background-color: #32AFC3;
  color: white;
  border: 2px solid #32AFC3;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0;
}

.platform-page .hero-cta .cta-primary-button:hover {
  background-color: transparent;
  color: #32AFC3;
}

.platform-page .hero-cta .pricing-link {
  font-size: 1.2rem;
  color: white;
  margin: 0;
  cursor: pointer;
  transition: color 0.3s ease;
  position: relative;
  display: inline-block;
}

.platform-page .hero-cta .pricing-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background-color: #32AFC3;
  transition: width 0.3s ease;
  transform-origin: left;
}

.platform-page .hero-cta .pricing-link:hover {
  color: #32AFC3;
}

.platform-page .hero-cta .pricing-link:hover::after {
  width: 100%;
}

/* Features Section */
.platform-page .features-section {
  background-color: #1C2B40;
  position: relative;
  overflow: hidden;
}

.platform-page .features-tag {
  display: inline-block;
  padding: 8px 24px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 32px;
}

.platform-page .features-title {
  font-size: 4rem;
  color: white;
  margin: 0 0 80px 0;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}

.platform-page .features-image-container {
  position: relative;
  width: 100%;
  height: 800px;
  margin-top: 80px;
  overflow: hidden;
}

.platform-page .ai-powerful-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  opacity: 1;
}

.platform-page .consolidation-image-svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.platform-page .code-scroll-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  overflow: hidden;
}

.platform-page .code-scroll-gradient-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mask-image: linear-gradient(to bottom, transparent 0%, transparent 20%, black 40%, black 100%);
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, transparent 20%, black 40%, black 100%);
  overflow: hidden;
}

.platform-page .code-scroll {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 80%;
  font-family: 'Courier New', monospace;
  font-size: 16px;
  line-height: 1.6;
  color: #9f9f9f;
  white-space: pre;
  text-align: left;
  animation: scrollCode 30s linear infinite;
  z-index: 5;
}

@keyframes scrollCode {
  0% {
    transform: translate(-50%, 0%);
    opacity: 0;
  }
  5% {
    transform: translate(-50%, 0%);
    opacity: 1;
  }
  95% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 0;
  }
}

/* Feature Background Shapes */
.platform-page .features-background-shape-top,
.platform-page .features-background-shape,
.platform-page .features-background-shape-bottom {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  border-radius: 24px;
}

.platform-page .features-browser-background {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  max-width: 1400px;
  margin: 0 auto;
  background-image: url('../../images/frontpage/browser_background.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 3;
}

.platform-page .features-background-shape-top {
  background: linear-gradient(145deg, #32AFC3 0%, #7B96E6 50%, #9F5FE9 100%);
  clip-path: polygon(0% 0%, 0% 20%, 100% 0%);
  opacity: 0.85;
  z-index: 2;
}

.platform-page .features-background-shape {
  background: linear-gradient(135deg, #7B96E6 0%, #9F5FE9 40%, #32AFC3 100%);
  clip-path: polygon(0% 20%, 100% 0%, 100% 80%, 0% 100%);
  opacity: 0.85;
  z-index: 2;
}

.platform-page .features-background-shape-bottom {
  background: linear-gradient(135deg, #32AFC3 0%, #1C2B40 100%);
  clip-path: polygon(0% 80%, 100% 60%, 100% 100%, 0% 100%);
  opacity: 0.6;
  z-index: 1;
}

/* Model Section */
.platform-page .model-section {
  background-color: #1C2B40;
  padding-top: 4rem;
  position: relative;
}

.platform-page .model-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 6rem;
  align-items: start;
}

.platform-page .model-text-left h2,
.platform-page .model-text-right h2 {
  font-size: 2.5rem;
  color: white;
  margin: 0 0 24px 0;
  font-weight: 600;
  line-height: 1.2;
}

.platform-page .model-text-left p,
.platform-page .model-text-right p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 0 0 32px 0;
  line-height: 1.6;
}

.platform-page .model-link {
  color: #32AFC3;
  font-size: 1.1rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  transition: color 0.3s ease;
}

.platform-page .model-link:hover {
  color: #F8A1D1;
}

/* Timeline Styles */
.platform-page .timeline-container {
  margin-top: 32px;
  margin-left: -16px;
  width: 100%;
}

.platform-page .custom-timeline {
  padding-left: 0;
}

.platform-page .custom-timeline .p-timeline-event {
  display: flex;
}

.platform-page .custom-timeline .p-timeline-event-content {
  margin-left: 1.5rem;
  flex: 1;
}

.platform-page .custom-timeline .p-timeline-event-marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 2px solid #32AFC3;
  background-color: #1C2B40;
  margin: 0;
  flex-shrink: 0;
}

.platform-page .custom-timeline .p-timeline-event-connector {
  width: 2px;
  background-color: rgba(255, 255, 255, 0.2);
  height: 110px;
}

.platform-page .custom-timeline .model-point {
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
  padding: 0;
}

.platform-page .custom-timeline .point-title {
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: left;
}

.platform-page .custom-timeline .point-text {
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.platform-page .p-timeline-event-opposite {
  display: none;
}

/* Feature List Section */
.platform-page .feature-list-section {
  background-color: #1C2B40;
  padding: 120px 0;
  position: relative;
}

.platform-page .feature-list-platform {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

.platform-page .feature-item-platform {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;
}

.platform-page .feature-item-platform.reverse {
  direction: ltr;
}

.platform-page .feature-text-platform {
  text-align: left;
}

.platform-page .feature-text-platform h2 {
  font-size: 2rem;
  color: white;
  margin: 0 0 24px 0;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
}

.platform-page .feature-text-platform p {
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  line-height: 1.6;
  text-align: left;
}

.platform-page .feature-image-container-platform {
  position: relative;
  width: 100%;
  height: 500px;
  border-radius: 24px;
  overflow: hidden;
}

.platform-page .feature-image-gradient-platform {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  height: 80%;
  border-radius: 24px;
}

.platform-page .feature-image-platform {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  z-index: 2;
  object-fit: contain;
}

.platform-page .feature-image-platform-executive-summary {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  height: auto;
  z-index: 2;
  object-fit: contain;
}

.platform-page .feature-image-platform-flags {
  position: absolute;
  bottom: 25%;
  left: 51%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  z-index: 2;
  object-fit: contain;
}

.platform-page .feature-image-platform-dependencies {
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  z-index: 2;
  object-fit: contain;
}

.platform-page .feature-image-platform-file-level-insights {
  position: absolute;
  bottom: 32%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  z-index: 2;
  object-fit: contain;
}

.platform-page .gradient-1 {
  background: linear-gradient(135deg, #32AFC3 0%, #9F5FE9 100%);
  opacity: 0.9;
}

.platform-page .gradient-2 {
  background: linear-gradient(135deg, #F8A1D1 0%, #32AFC3 100%);
  opacity: 0.9;
}

.platform-page .gradient-3 {
  background: linear-gradient(75deg, #eb71cc 0%, #8773df 100%);
  opacity: 0.9;
}

.platform-page .gradient-4 {
  background: linear-gradient(200deg, #32c398 0%, #2ac3ff 100%);
  opacity: 0.9;
}

.platform-page .gradient-5 {
  background: linear-gradient(95deg, #73affd 0%, #9F5FE9 100%);
  opacity: 0.9;
}

/* CTA Section */
.platform-page .cta-gradient-title {
  font-size: 2.8rem;
  font-weight: 600;
  margin-bottom: 48px;
  text-align: center;
  background: linear-gradient(90deg, #F8A1D1 0%, #9F5FE9 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Animations */
@keyframes hero-gradient-flow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Consolidated Media Queries */
@media (max-width: 1200px) {
  .platform-page .platform-hero-text {
    max-width: 700px;
  }

  .platform-page .hero-gradient-text,
  .platform-page .hero-title h1 {
    font-size: 3rem;
  }

  .platform-page .features-image-container {
    height: 700px;
  }

  .platform-page .model-grid,
  .platform-page .feature-item-platform {
    gap: 60px;
  }
}

@media (max-width: 1100px) {
  .platform-page .hero-title {
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .platform-page .gradient-wrapper {
    margin-bottom: 4px;
  }

  .platform-page .hero-gradient-text {
    font-size: 2.8rem;
  }

  .platform-page .hero-title h1 {
    font-size: 2.8rem;
    margin-left: 0;
    white-space: normal;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .platform-page .platform-hero {
    padding: 60px 0 0 0;
  }

  .platform-page .platform-hero-content {
    padding: 0 16px;
  }

  .platform-page .hero-gradient-text,
  .platform-page .hero-title h1 {
    font-size: 2.5rem;
  }

  .platform-page .hero-cta {
    flex-direction: column;
    gap: 24px;
    width: 100%;
  }

  .platform-page .hero-cta .cta-primary-button {
    width: 100%;
  }

  .platform-page .features-section {
    padding: 80px 0 160px 0;
  }

  .platform-page .features-image-container {
    height: 500px;
  }

  .platform-page .model-section,
  .platform-page .feature-list-section {
    padding: 80px 0;
  }

  .platform-page .model-grid {
    grid-template-columns: 1fr;
    gap: 48px;
  }

  .platform-page .feature-list-platform {
    gap: 80px;
  }

  .platform-page .feature-item-platform {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .platform-page .feature-item-platform.reverse {
    direction: ltr;
  }

  .platform-page .model-text-left h2,
  .platform-page .model-text-right h2,
  .platform-page .feature-text-platform h2,
  .platform-page .cta-gradient-title {
    font-size: 2rem;
  }

  .platform-page .timeline-container {
    margin-top: 24px;
  }

  .platform-page .custom-timeline .model-point {
    padding: 0 0 24px 0;
  }

  .platform-page .custom-timeline .point-title {
    font-size: 1rem;
  }

  .platform-page .custom-timeline .point-text {
    font-size: 0.9rem;
  }

  .platform-page .code-scroll {
    font-size: 14px;
    width: 95%;
  }

  .platform-page .code-scroll-1 {
    left: 2%;
  }

  .platform-page .code-scroll-2 {
    left: 33%;
  }

  .platform-page .code-scroll-3 {
    left: 64%;
  }

  .platform-page .gradient-1 {
    height: 40%;
  }
  
  .platform-page .gradient-2 {
    height: 60%;
  }
  
  .platform-page .gradient-3 {
    height: 50%;
  }
  
  .platform-page .gradient-4 {
    height: 40%;
  }
  
  .platform-page .gradient-5 {
    height: 40%;
  }

  .platform-page .feature-image-platform{
    top: 30%;
  }

  .platform-page .feature-image-platform-executive-summary{
    bottom: 30%;
  }

  .platform-page .feature-image-platform-flags{
    bottom: 45%;
  }

  .platform-page .feature-image-platform-dependencies{
    bottom: 53%;
  }

  .platform-page .feature-image-platform-file-level-insights{
    bottom: 58%;
  }
}

@media (max-width: 480px) {
  .platform-page .platform-hero {
    padding: 40px 0;
  }

  .platform-page .hero-gradient-text,
  .platform-page .hero-title h1 {
    font-size: 2rem;
  }

  .platform-page .hero-title {
    gap: 12px;
  }

  .platform-page .features-section {
    padding: 60px 0 120px 0;
  }

  .platform-page .features-image-container {
    height: 400px;
  }

  .platform-page .features-title {
    font-size: 3rem;
  }
}

.platform-page .model-section .platform-hero-content {
  text-align: left;
  align-items: flex-start;
  padding: 0 24px;
}

.platform-page .model-text-left h3,
.platform-page .model-text-right h3 {
  font-size: 2.5rem;
  color: white;
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;
}

.platform-page .model-text-left p,
.platform-page .model-text-right p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  line-height: 1.6;
  text-align: left;
}

/* Consolidation Section */
.platform-page .consolidation-section {
  background-color: white;
  padding: 120px 0;
  position: relative;
  overflow: hidden;
}

.platform-page .consolidation-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.platform-page .consolidation-header {
  margin-bottom: 64px;
  text-align: left;
  width: 100%;
}

.platform-page .consolidation-header h2 {
  font-size: 3.5rem;
  color: #1C2B40;
  margin: 0 0 24px 0;
  font-weight: 600;
  line-height: 1.2;
  width: 100%;
}

.platform-page .consolidation-header h3 {
  font-size: 2rem;
  color: #1C2B40;
  margin: 0;
  font-weight: 600;
  line-height: 1.4;
  width: 100%;
}

.platform-page .consolidation-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: start;
}

.platform-page .consolidation-left {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.platform-page .consolidation-benefits {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.platform-page .consolidation-benefit {
  display: flex;
  align-items: center;
  gap: 12px;
}

.platform-page .consolidation-benefit i {
  color: #9F5FE9;
  font-size: 1.2rem;
}

.platform-page .consolidation-benefit span {
  color: #1C2B40;
  font-size: 1.1rem;
  font-weight: 500;
}

.platform-page .consolidation-cta {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.platform-page .consolidation-cta h4 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1C2B40;
  margin-bottom: -15px;
}

.platform-page .consolidation-cta p {
  font-size: 1.1rem;
  color: rgba(28, 43, 64, 0.8);
  margin: 0;
  line-height: 1.6;
}

.platform-page .consolidation-image-container {
  position: relative;
  width: 100%;
  height: 400px;
  border-radius: 24px;
  overflow: hidden;
}

.platform-page .consolidation-image-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, #9F5FE9 0%, #32AFC3 100%);
  opacity: 0.1;
  border-radius: 24px;
}

.platform-page .consolidation-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
  z-index: 2;
  object-fit: contain;
}

@media (max-width: 1024px) {
  .platform-page .consolidation-header h2 {
    font-size: 2.5rem;
  }

  .platform-page .consolidation-content {
    grid-template-columns: 1fr;
    gap: 60px;
  }

  .platform-page .consolidation-image-container {
    display: none;
  }
}

@media (max-width: 768px) {
  .platform-page .consolidation-section {
    padding: 80px 0;
  }

  .platform-page .consolidation-header {
    margin-bottom: 48px;
  }

  .platform-page .consolidation-header h2 {
    font-size: 2.2rem;
  }
}

/* Pricing Section */
.platform-page .pricing-section {
  background: linear-gradient(to right, rgba(243, 238, 255, 0.5), rgba(230, 238, 255, 0.5));
  padding: 120px 0 160px 0;
  position: relative;
  overflow: hidden;
}

.platform-page .pricing-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.platform-page .pricing-header {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 80px;
  align-items: flex-start;
}

.platform-page .pricing-text {
  max-width: 800px;
}

.platform-page .pricing-text h2 {
  font-size: 3rem;
  color: #1C2B40;
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
}

.platform-page .pricing-links {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
}

.platform-page .pricing-link-button {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  min-width: 280px;
  background: linear-gradient(135deg, rgba(159, 95, 233, 0.8) 0%, rgba(248, 161, 209, 0.8) 100%);
  border-radius: 12px;
  color: #1C2B40;
  font-size: 1.1rem;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  white-space: nowrap;
  /* Display none for now */
  display: none;
}

.platform-page .pricing-link-button:first-child {
  background: linear-gradient(135deg, rgba(99, 188, 202, 0.8) 0%, rgba(123, 150, 230, 0.8) 100%);
}

.platform-page .pricing-link-button:first-child:hover {
  background: linear-gradient(135deg, rgba(99, 188, 202, 0.85) 0%, rgba(123, 150, 230, 0.85) 100%);
}

.platform-page .pricing-link-button:last-child:hover {
  background: linear-gradient(135deg, rgba(159, 95, 233, 0.85) 0%, rgba(248, 161, 209, 0.85) 100%);
}

.platform-page .pricing-link-button i {
  margin-left: 12px;
  font-size: 1rem;
  transition: transform 0.3s ease;
}

.platform-page .pricing-link-button:hover i {
  transform: translateX(4px);
}

@media (max-width: 1024px) {
  .platform-page .pricing-header {
    grid-template-columns: 1fr;
    gap: 48px;
  }

  .platform-page .pricing-text {
    max-width: 100%;
  }

  .platform-page .pricing-text h2 {
    font-size: 3rem;
  }

  .platform-page .pricing-links {
    padding-top: 0;
  }

  .platform-page .pricing-link-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .platform-page .pricing-section {
    padding: 80px 0 120px 0;
  }

  .platform-page .pricing-text h2 {
    font-size: 2.5rem;
  }
}

@media (max-width: 480px) {
  .platform-page .pricing-link-button {
    min-width: unset;
    width: 100%;
    padding: 20px;
  }
}
