.hotspots-bubble-chart {
    position: relative;
    width: 100%;
    height: 500px;
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    overflow: hidden;
}

.hotspots-bubble-chart svg {
    width: 100%;
    height: 100%;
}

.bubble {
    cursor: pointer;
}

.bubble circle {
    transition: opacity 0.2s ease-in-out, stroke-width 0.2s ease-in-out;
}

.bubble.selected circle {
    filter: drop-shadow(0 0 4px rgba(0,0,0,0.2));
}

.hotspots-tooltip.p-tooltip {
    z-index: 1000;
}

.hotspots-tooltip.p-tooltip .p-tooltip-text {
    background: #1F2937;
    color: #F9FAFB;
    padding: 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    white-space: pre-line;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
    max-width: 300px;
    pointer-events: none;
}

.legend {
    position: absolute;
    top: 20px;
    right: 20px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.legend text {
    font-family: system-ui, -apple-system, sans-serif;
    font-size: 12px;
}