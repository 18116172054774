/* DividerStyles.css */

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
  }

.custom-divider {
    justify-content: flex-start;
    margin-bottom: 40px;
  }

.p-divider-content {
    background-color: #F9F9F9;
}

.custom-divider-title {
    font-size: 16px;
    font-weight: normal;
    color: #8D8D8D;
    margin-top: 20px;
    background-color: #F9F9F9;
  }
  
  .custom-divider .p-divider-content {
    /* Add any specific styles for the content if needed */
  }
  
  .custom-divider-icon {
    margin-right: 0.5rem; /* 2 is replaced with 0.5rem for proper spacing */
  }
  
  .custom-divider-title {
    font-weight: normal;
  }