.score-graph-indicator {
    border-radius: 50%;
    background-color: #2CB392;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    color: white;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -17px;
    left: 20px;
    z-index: 2;
}

.score-bar {
    width: 100%;
    height: 8px;
    background-color: #E0E0E0;
    border-radius: 8px;
    position: relative;
    margin-left: 18px;
  }

  .avg-line {
    transition: all 0.3s ease;
  }
