.dev-activity-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 0; /* Important for flex container */
}

.dev-activity-controls {
    display: flex;
    gap: 20px;
    margin-bottom: 1rem;
    flex-wrap: wrap;
    flex: 0 0 auto; /* Don't allow this to grow or shrink */
}

.dev-activity-search {
    flex: 1;
    min-width: 200px;
}

.dev-activity-timerange {
    width: 200px;
}

.dev-activity-search-input {
    width: 250px;
}

.dev-activity-timerange-dropdown {
    width: 100%;
    max-width: 250px;
}

.dev-activity-developers-container {
    display: flex;
    margin-bottom: 1rem;
    background: white;
    border-radius: 8px;
    padding: 1rem;
    gap: 1rem;
    min-height: 370px;
}


.dev-activity-developers {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 30%;
    min-width: 250px;
}

.dev-activity-developer-vertical-line {
    width: 1px;
    background-color: #E5E7EB;
    margin: 0 1rem;
}

.dev-activity-developer-info {
    flex: 1;
    min-width: 0; /* Prevent flex item from overflowing */
}

.dev-activity-developer-chip {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-size: 0.9rem;
    width: 100%;
}

.dev-activity-developer-chip:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.dev-activity-developer-chip .dev-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dev-activity-developer-chip .dev-commit-count {
    font-size: 0.8rem;
    opacity: 0.8;
    margin-left: 8px;
}

.dev-activity-developer-chip.selected {
    font-weight: 500;
}

.dev-activity-developer-chip.remaining-chip {
    font-size: 0.8rem;
    opacity: 0.7;
    justify-content: center;
}

.dev-activity-graph {
    position: relative;
    width: 100%;
    background: white;
    margin-bottom: 1rem;
}

.dev-activity-graph svg {
    width: 100%;
    height: 100%;
    overflow: visible;
}

.dev-activity-graph .grid-lines {
    z-index: 1;
}

.dev-activity-graph path {
    z-index: 2;
    position: relative;
    transition: all 0.2s ease;
}

.dev-activity-graph text {
    z-index: 3;  /* Ensure text is always on top */
    font-size: 14px;
}

.dev-activity-graph path:hover {
    opacity: 1;
    stroke-width: 3px;
}

.dev-activity-graph .x-axis,
.dev-activity-graph .y-axis {
    color: #9ca3af;
    font-size: 12px;
}

.dev-activity-graph .line {
    transition: all 0.2s ease;
}

.dev-activity-graph .line:hover {
    stroke-width: 3px;
    filter: drop-shadow(0 0 2px rgba(0,0,0,0.2));
}

.dev-activity-tooltip {
    white-space: pre-line;
    max-width: 300px;
}

.dev-activity-tooltip .p-tooltip-text {
    white-space: pre-line;
    font-size: 13px;
    line-height: 1.5;
    padding: 12px;
    background: white;
    color: #323232;
    border: 1px solid #e5e7eb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 6px;
}

.tooltip-dot {
    cursor: pointer;
    transition: all 0.2s ease;
}

.tooltip-dot:hover {
    stroke-width: 2px;
    stroke: rgba(255, 255, 255, 0.5);
}

.dev-activity-legend,
.dev-activity-legend-item,
.dev-activity-legend-color {
    display: none;
}

.dev-activity-no-data {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #999;
    font-size: 1rem;
    text-align: center;
    fill: #9CA3AF;
}

.dev-activity-overview-graph {
    margin-bottom: 1rem;
    cursor: pointer;
    position: relative;
}

.dev-activity-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    color: #6B7280;
}

.developer-commit-indicators rect {
    transition: opacity 0.2s ease;
}

.developer-commit-indicators rect:hover {
    opacity: 1;
}

/* When dragging the time window, make indicators more visible */
.time-window-dragging .developer-commit-indicators rect {
    opacity: 1;
}

/* Responsive adjustments */
@media screen and (max-width: 992px) {
    .dev-activity-graph {
        padding: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .dev-activity-controls {
        flex-direction: column;
    }

    .dev-activity-search,
    .dev-activity-timerange {
        width: 100%;
    }

    .dev-activity-developers-container {
        flex-direction: column;
    }

    .dev-activity-developers {
        width: 100%;
    }

    .dev-activity-developer-vertical-line {
        width: 100%;
        height: 1px;
        margin: 1rem 0;
    }

    .dev-activity-developer-info {
        width: 100%;
    }

    .dev-activity-developer-chip:hover {
        transform: translateY(-2px);
    }
}

.dev-activity-toggle {
    font-size: 0.85rem;
    color: #32AFC3;
    cursor: pointer;
    transition: all 0.2s ease;
    margin-left: 15px;
    margin-top: -5px;
}

.dev-activity-toggle:hover {
    color: #229478;
    text-decoration: underline;
}

.dev-info-header {
    margin-bottom: 2rem;
}

.dev-info-label {
    color: #6B7280;
    font-size: 0.875rem;
    font-weight: 500;
    display: block;
    margin-bottom: 0.75rem;
}

.dev-info-profile {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
}

.dev-info-avatar {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #E5E7EB;
}

.dev-info-name-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.dev-info-name {
    font-size: 1.125rem;
    font-weight: 600;
}

.dev-info-email {
    font-size: 0.875rem;
    color: #6B7280;
}

.dev-info-data {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.dev-info-row {
    display: flex;
    align-items: center;
    gap: 1rem;
    min-height: 32px; /* Ensure consistent height */
}

.dev-info-row .dev-info-label {
    min-width: 140px;
    margin-bottom: 0;
}

.dev-info-row .dev-info-value {
    flex: 1;
    font-size: 0.875rem;
    max-width: 400px;
}

.ratio-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 100%;
    max-width: 300px;
}

.ratio-bar-container {
    position: relative;
    width: 100%;
    height: 8px;
    background-color: #F3F4F6;
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
    display: flex;
}

.ratio-bar-container:hover {
    opacity: 0.9;
}

.ratio-text {
    font-size: 0.875rem;
    min-width: 60px;
}

.ratio-bar {
    width: 100%;
    height: 100%;
    display: block;
}

.ratio-bar rect.additions {
    fill: #2CB392 !important;
    transition: all 0.3s ease-in-out;
}

.ratio-bar rect.deletions {
    fill: #E83636 !important;
    transition: all 0.3s ease-in-out;
}

.ratio-bar-container:hover rect {
    opacity: 0.9;
}

/* Add hover states */
.ratio-bar-container:hover .ratio-bar rect.additions {
    fill: #25a382 !important;
}

.ratio-bar-container:hover .ratio-bar rect.deletions {
    fill: #d32f2f !important;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .dev-info-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .dev-info-row .dev-info-label {
        min-width: auto;
    }
}

.dev-info-link {
    color: #32AFC3;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.2s ease;
}

.dev-info-link:hover {
    color: #229478;
    text-decoration: underline;
}

.domain-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    width: 100%;
}

.domain-bar-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    cursor: pointer;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.domain-bar-container:hover {
    cursor: pointer;
}

.domain-label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.domain-label-text {
    font-size: 14px;
    color: #4B5563;
    transition: color 0.3s ease-in-out;
}

.domain-count {
    font-size: 12px;
    color: #6B7280;
    font-family: 'Roboto Mono', monospace;
    transition: all 0.3s ease-in-out;
}

.domain-bar-wrapper {
    background-color: #F3F4F6;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
}

.domain-bar {
    height: 100%;
    transition: all 0.3s ease-in-out;
}

/* Hover effects */
.domain-bar-container:hover .domain-bar {
    opacity: 0.8;
}

.domain-bar-container:hover .domain-count {
    background: #E5E7EB;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .dev-info-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .dev-info-row .dev-info-label {
        min-width: auto;
    }

    .domain-container {
        width: 100%;
    }
}

.remaining-domains-text {
    margin-top: 8px;
    font-size: 13px;
    color: #6B7280;
    cursor: pointer;
    padding: 4px 8px;
    border-radius: 4px;
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.remaining-domains-text.exiting {
    opacity: 0;
    transform: translateY(-10px);
}

.remaining-domains-text:hover {
    background-color: #F3F4F6;
    color: #4B5563;
}

.p-tooltip {
    max-width: 400px !important;
    z-index: 1000;
}

.p-tooltip .p-tooltip-text {
    padding: 8px 12px;
    font-size: 13px;
    background-color: #1F2937;
    color: #F9FAFB;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    line-height: 1.4;
}

.p-tooltip.p-tooltip-top {
    padding-bottom: 8px;
}

.p-tooltip.p-tooltip-right {
    padding-left: 8px;
}

/* Ensure the domain container has proper z-index for tooltips */
.domain-bar-container {
    position: relative;
    z-index: 1;
}

.domain-bar-container:hover {
    z-index: 2;
}

/* Ensure the remaining domains text has proper z-index for tooltips */
.remaining-domains-text {
    position: relative;
    z-index: 1;
}

.remaining-domains-text:hover {
    z-index: 2;
}

.domain-bar-container.entering {
    opacity: 0;
    transform: translateX(-10px);
}

.domain-bar-container.exiting {
    opacity: 0;
    transform: translateX(10px);
} 