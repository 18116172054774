/* Header Styles */
.header {
  display: flex;
  align-items: center;
  margin-left: 50px;
  padding-left: 15px;
  color: darkgray;
  margin-top: 10px;
}

.header a {
  display: flex; 
  align-items: center; 
  text-decoration: none; 
  color: #323232;
}

.header a img {
  height: 50px; 
  width: 50px; 
  object-fit: contain; 
  margin-right: 10px;
}

.header-text {
  display: flex;
  flex-direction: column;
}

.file-count {
  font-size: medium;
  color: #8D8D8D;
}

/* New styles for repo URL */
.repository-url {
  color: #8D8D8D;
  font-style: italic;
  font-size: medium;
}

/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
}

.audit-name, .auditing_loader {
  width: fit-content;
  font-weight: bold;
  font-size: 30px;
  margin-top: 5px;
}

.audit-name {
  color: #323232;
}

.auditing_loader {
  color: transparent;
  background: linear-gradient(90deg, #32AFC3 calc(50% + 0.5ch), #323232 0);
  background-size: calc(200% + 1ch) 100%;
  -webkit-background-clip: text;
  background-clip: text;
  animation: slideBg 2s infinite steps(11);
}

@keyframes slideBg {
  to {
    background-position: left;
  }
}

.auditing_loader::before {
  content: "Auditing...";
}

@media (max-width: 768px) {
  .header {
    max-width: 600px;
  }
  
}

@media (max-width: 480px) {
  .header {
    max-width: 300px;
  }
  
}
