/* Base styles */
.export-audit-results {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.export-audit-results > * {
    box-sizing: border-box;
    align-self: flex-start;
}

/* Typography */
.export-audit-results h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #1a1a1a;
}

.export-audit-results h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
    color: #4b5563;
    font-weight: normal;
}

/* Downloads data view container */
.downloads-container {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}

/* Card styling */
.download-section .flex {
    display: grid;
    grid-template-columns: 100px 1fr auto;
    gap: 2rem;
    align-items: center;
    background-color: #fff;
    border: 1px solid #e5e7eb;
    padding: 1.5rem;
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s ease-in-out;
}

.download-section .flex:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Excel icon styling */
.excel-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 
                0 2px 4px -1px rgba(0, 0, 0, 0.06);
    width: 80px;
    height: 80px;
}

.excel-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* Content section */
.download-section-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.download-section-content .text-2xl {
    color: #1a1a1a;
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.download-section-content .text-500 {
    color: #4b5563;
    font-size: 0.875rem;
    line-height: 1.5;
}

/* Button styling */
.export-button {
    background-color: #2CB392;
    border-color: #2CB392;
    color: white;
    min-width: 200px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;  /* Space between icon and text */
}

.export-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.export-button .p-button-label {
    color: white !important;
    font-weight: bold !important;
}

.export-button .p-button-icon {
    color: white !important;
    font-size: 1rem;
}

.export-button .pi-spin {
    font-size: 1rem;
}

/* Progress spinner */
.p-progress-spinner {
    width: 20px !important;
    height: 20px !important;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .download-section .flex {
        grid-template-columns: 1fr;
        gap: 1rem;
        text-align: center;
    }

    .excel-icon-container {
        margin: 0 auto;
    }

    .content-section {
        text-align: center;
    }

    .p-button.p-button-primary {
        width: 100%;
        justify-content: center;
    }
}

@media screen and (max-width: 480px) {
    .export-audit-results {
        width: 100%;
        padding: 0.5rem;
    }

    .content-section .text-2xl {
        font-size: 1.25rem;
    }

    .content-section .text-500 {
        font-size: 0.75rem;
    }
}