@keyframes move-dots {
  0% {
    transform: translateX(-4px);
    opacity: 0.7;
  }
  50% {
    transform: translateX(4px);
    opacity: 1;
  }
  100% {
    transform: translateX(-4px);
    opacity: 0.7;
  }
}

@keyframes rotate-c {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-animation-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  width: 100%;
  background: rgba(255, 255, 255, 0.98);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
}

.loading-animation {
  width: 80px;
  height: 80px;
  position: relative;
}

.loading-animation svg {
  width: 100%;
  height: 100%;
  overflow: visible; /* Allow dots to render outside SVG viewport */
}

.logo-c {
  transform-origin: center;
  animation: rotate-c 4s linear infinite;
}

.logo-dot-1 {
  animation: move-dots 1.2s ease-in-out infinite;
}

.logo-dot-2 {
  animation: move-dots 1.2s ease-in-out infinite 0.4s;
}

.logo-dot-3 {
  animation: move-dots 1.2s ease-in-out infinite 0.8s;
} 