.discover-page {
  position: relative;
  min-height: 100vh;
  background-color: #1C2B40;
}

/* Hero Section */
.discover-page .discover-hero {
  padding: 80px 0 40px 0;
  width: 100%;
  position: relative;
  z-index: 2;
}

.discover-page .discover-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.discover-page .discover-hero-text {
  max-width: 800px;
  margin-bottom: 48px;
}

.discover-page .discover-hero-text h3 {
  font-size: 1.2rem;
  color: white;
  margin: 0;
  font-weight: 400;
}

/* Hero Title Styles */
.discover-page .hero-title {
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  margin: 16px 0;
}

.discover-page .gradient-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0;
  white-space: nowrap;
}

.discover-page .hero-gradient-text {
  font-size: 3.5rem;
  font-weight: 600;
  background: linear-gradient(
    45deg,
    #F8A1D1 0%,
    #9F5FE9 25%,
    #F8A1D1 50%,
    #9F5FE9 75%,
    #F8A1D1 100%
  );
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hero-gradient-flow 15s ease infinite;
  display: inline;
  white-space: nowrap;
}

.discover-page .hero-title h1 {
  font-size: 3.5rem;
  color: white;
  margin: 0;
  font-weight: 600;
  line-height: 1.2;
  display: inline;
  white-space: nowrap;
}

.discover-page .hero-description-discover {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 24px 0 0 0;
  line-height: 1.6;
  font-weight: 400;
}

/* Audits Section */
.discover-page .audits-section {
  padding: 40px 0 80px 0;
  background-color: #1C2B40;
}

.discover-page .audits-content {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

/* Search Styles */
.discover-page .audit-search {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.discover-page .audit-search .p-input-icon-left {
  width: 100%;
  max-width: 600px;
}

.discover-page .audit-search .p-input-icon-left i {
  color: rgba(255, 255, 255, 0.7);
  left: 1rem;
  top: 12px;
}

.discover-page .audit-search .search-input {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  border-radius: 8px;
  padding: 1rem 1rem 1rem 3rem;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.discover-page .audit-search .search-input:hover {
  border-color: rgba(255, 255, 255, 0.3);
}

.discover-page .audit-search .search-input:focus {
  border-color: #32AFC3;
  box-shadow: 0 0 0 1px #32AFC3;
}

.discover-page .audit-search .search-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.discover-page .audits-list {
  width: 100%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 24px;
}

/* Audit Card Styles */
.discover-page .audit-item {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 8px;
}

.discover-page .audit-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.discover-page .audit-item:hover {
  background: rgba(255, 255, 255, 0.08);
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.discover-page .repo-logo {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  margin-right: 16px;
  margin-left: 12px;
}

.discover-page .audit-details {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
}

.discover-page .repo-name-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
}

.discover-page .repo-name {
  color: white;
  font-weight: 600;
  font-size: 1.3rem;
  margin-bottom: 4px;
}

.discover-page .public-icon {
  color: #32AFC3;
  font-size: 1rem;
}

.discover-page .repo-url-container {
  width: 100%;
  min-width: 0;
  padding-right: 20px;
  overflow: hidden;
  margin-bottom: 12px;
}

.discover-page .repo-url {
  color: #8D8D8D;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 100%;
}

.discover-page .audit-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 150px;
  margin-right: 12px;
}

.discover-page .ai-synthesis {
  color: white;
  font-weight: 600;
  font-size: 0.9rem;
  margin-bottom: 4px;
  margin-top: 20px;
}

.discover-page .file-count {
  color: #8D8D8D;
  font-size: 0.9rem;
  margin-bottom: 4px;
}

.discover-page .audit-status {
  color: #32AFC3;
  font-size: 0.9rem;
  font-weight: 500;
}

/* Score Grid Styles */
.discover-page .audit-scores-grid {
  grid-column: 1 / -1;
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;
}

.discover-page .no-results-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.discover-page .no-results-message i {
  color: white;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.discover-page .no-results-message p {
  color: white;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.discover-page .no-results-cta {
  color: white;
  font-size: 1.1rem;
  margin: 1.5rem 0;
}

.discover-page .no-results-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.discover-page .no-results-primary-button,
.discover-page .no-results-secondary-button {
  padding: 12px 24px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  min-width: 150px;
}

.discover-page .no-results-primary-button {
  background-color: #32AFC3;
  color: white;
  border: 2px solid #32AFC3;
}

.discover-page .no-results-primary-button:hover {
  background-color: white;
  color: #32AFC3;
}

.discover-page .no-results-secondary-button {
  background-color: transparent;
  color: #32AFC3;
  border: 2px solid #32AFC3;
}

.discover-page .no-results-secondary-button:hover {
  background-color: #32AFC3;
  color: white;
}

/* Load More Button */
.discover-page .load-more-button {
  background: #32AFC3;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 6px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.discover-page .load-more-button:hover {
  background: #2b95a6;
}

.discover-page .load-more-button.disabled {
  background: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.5);
  cursor: not-allowed;
}

/* Animations */
@keyframes hero-gradient-flow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .discover-page .discover-hero-text {
    max-width: 700px;
  }

  .discover-page .hero-gradient-text,
  .discover-page .hero-title h1 {
    font-size: 3rem;
  }
}

@media (max-width: 768px) {
  .discover-page .discover-hero {
    padding: 60px 0;
  }

  .discover-page .hero-title {
    flex-direction: column;
    gap: 8px;
  }

  .discover-page .hero-gradient-text,
  .discover-page .hero-title h1 {
    font-size: 2.5rem;
  }

  .discover-page .audits-content {
    padding: 0 16px;
  }

  .discover-page .audit-item {
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0;
  }

  .discover-page .audit-info {
    align-items: flex-start;
    margin-top: 12px;
    margin-right: 12px;
  }

  .discover-page .no-results-buttons {
    flex-direction: column;
    width: 100%;
    max-width: 250px;
  }

  .discover-page .no-results-primary-button,
  .discover-page .no-results-secondary-button {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .discover-page .discover-hero {
    padding: 40px 0;
  }

  .discover-page .hero-gradient-text,
  .discover-page .hero-title h1 {
    font-size: 2rem;
  }

  .discover-page .audits-content {
    padding: 0 12px;
  }

  .discover-page .audit-scores-grid {
    grid-template-columns: 1fr;
  }
}

.discover-page .rate-limit-error {
  margin: 1rem 0;
  max-width: 600px;
}

.discover-page .rate-limit-error .p-message {
  width: 100%;
  background: rgba(255, 193, 7, 0.1);
  border: 1px solid rgba(255, 193, 7, 0.2);
  border-radius: 8px;
}

.discover-page .rate-limit-error .p-message-text {
  color: rgba(255, 255, 255, 0.9);
}

/* Paginator Styles */
.discover-page .paginator-container {
  padding-top: 1rem;
}

.discover-page .public-audits-paginator {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: rgba(255, 255, 255, 0.0);
}

.discover-page .public-audits-paginator .p-paginator {
  background: transparent;
  border: none;
}

.discover-page .public-audits-paginator .p-paginator-element svg path {
  fill: white !important;
}

.discover-page .public-audits-paginator .p-paginator-element.p-disabled svg path {
  fill: #8D8D8D !important;
}

.discover-page .public-audits-paginator .p-paginator-element {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  margin: 0 2px;
  min-width: 2.5rem;
  height: 2.5rem;
  transition: all 0.2s ease;
}

.discover-page .public-audits-paginator .p-paginator-element.p-disabled {
  color: #8D8D8D !important;
  background: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
  cursor: not-allowed;
}

/* Loading State */
.discover-page .audits-list.loading {
  opacity: 0.7;
  pointer-events: none;
}

.discover-page .loading-spinner-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.discover-page .audits-list.hidden {
  display: none;
}