/* scoregraph.css */
.score-graph-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.chapter-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.score-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  animation: fadeInUp 0.5s ease-out forwards;
  animation-delay: var(--row-delay);
  transition: background-color 0.2s ease;
  padding: 0.5rem;
  position: relative;
}

.score-row:hover {
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

.score-title {
  color: #C049DE;
  font-weight: bold;
  flex: 0 0 auto;
  width: 20%;
  display: flex;
  align-items: center;
  gap: 8px;
}

.main-title {
  color: #C049DE;
  min-width: 170px;
}

.sub-title {
  color: #C049DE;
  font-weight: 500;
  min-width: 170px;
}

.score-slider-graph {
  flex: 1;
  padding-bottom: 10px;
}

.subpoints-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
}

.sub-row {
  opacity: 0.9;
}

.score-tooltip {
  position: absolute;
  background-color: #333;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9rem;
  z-index: 1000;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
}

.score-row:hover .score-tooltip {
  opacity: 1;
}

.score-tooltip::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 4px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
  z-index: 1000;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 450px) {
  .score-bar {
    /* Hide the score bar on mobile */
    display: none;
  }
}

