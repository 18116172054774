.badge-helper-content {
    padding: 1rem;
}

.badge-preview {
    background-color: #f8f9fa;
    border-radius: 4px;
    padding: 1rem;
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.badge-code {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 1rem 0;
}

.badge-code .p-inputtext {
    font-family: monospace;
    background-color: #f8f9fa;
}

.badge-instructions {
    margin-top: 2rem;
}

.badge-instructions ol {
    padding-left: 1.5rem;
    margin: 1rem 0;
}

.badge-instructions li {
    margin: 0.5rem 0;
    color: #495057;
}

.badge-instructions p {
    color: #666;
    font-style: italic;
    margin-top: 1rem;
} 