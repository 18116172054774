/* App.css */
.App {
  text-align: center;
}

* {

  box-sizing: border-box; 
}

.public-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.public-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto; /* Allow scrolling within the content area if needed */
  padding-top: 60px; /* Adjusted padding to account for the sticky header */
}

.app-content {
  margin-left: 50px; /* Initial padding to account for contracted sidebar */
  flex: 1;
  display: flex;
  flex-direction: column;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.sidebar-locked .app-content {
  margin-left: 200px; /* Adjusted margin when sidebar is expanded */
  padding-left: 0; /* Remove padding when sidebar is expanded */
  transition: margin-left 0.2s;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #32AFC3;
}

.p-dialog-header {
  background-color: #32AFC3;
}

.p-dialog-title {
  color: #282c34;
  margin-left: 1rem;
}

/* Changing the style of primereacts dialog close button */
.p-dialog-header-icon.p-dialog-header-close {
  border: none;
  background: none;
}

.p-dialog-header-icon.p-dialog-header-close:hover {
  background: none;
  border: 1px solid #323232;
}

.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: #6b7280;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}

.p-dialog .p-dialog-header-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.p-link {
  text-align: left;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  user-select: none;
}

/* App.css */

/* Style for h1 elements */
h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.5rem; 
  color: #323232;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Style for h2 elements */
h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.25rem;
  color: #323232;
  font-weight: bold;
}

/* Style for h3 elements */
h3 {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  color: #323232;
  font-weight: normal;
}

/* Style for h4 elements */
h4 {
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem; /* 14px */
  color: #8D8D8D;
  font-weight: normal;
}

p {
  font-size: 1rem;
  line-height: 1.5;
}

/* Remove redundant toast styles */
.p-toast-message-icon {
  display: none;
}

/* Add this at the end of your file */
.sticky-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto; /* This pushes the footer to the bottom */
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* Responsive adjustments */
@media (min-width: 768px) {
  html, body {
    font-size: 18px;
  }

  h1 {
    font-size: 2rem; /* 32px on base 16px */
  }

  .welcome-box {
    padding: 2rem;
  }
}

.app-container {
    min-height: 100vh;
    position: relative;
}

/* Toast styling */
.p-toast {
    z-index: 9999 !important;
}

.p-toast-message {
    margin: 0 1rem;
}

/* Ensure toast is visible during transitions */
.p-toast-top-right {
    top: 20px !important;
    right: 20px !important;
    position: fixed !important;
}