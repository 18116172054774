.skeleton-cell-content {
    height: 1.2rem;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
    width: 80%;
}

.skeleton-text {
    height: 1rem;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
    margin: 0.5rem 0;
}

.skeleton-input {
    width: 200px;
    opacity: 0.7;
    cursor: not-allowed;
}

.skeleton-editor {
    height: 250px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
    margin: 1rem 0;
}

.skeleton-button {
    width: 100px;
    height: 36px;
    background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
    border-radius: 4px;
    margin-top: 1rem;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}

.audit-scope-selection.skeleton {
    display: flex;
    flex-direction: row;
    margin-top: 35px;
    margin-left: 65px;
    margin-right: 310px;
    margin-bottom: 20px;
}

.main-content {
    flex: 1;
    margin-right: 20px;
}

.header-text h2 {
    font-size: 1.5em;
    color: #323232;
    margin-bottom: 15px;
}

.header-text .sub-text {
    font-size: 0.9em;
    color: #8D8D8D;
    margin-bottom: 8px;
}

.skeleton-sidebar {
    background: #fff;
    padding: 20px;
}

.audit-summary.skeleton {
    padding: 1rem;
}

.audit-summary.skeleton h2 {
    font-size: 1.3em;
    color: #323232;
    margin-bottom: 1rem;
}

.audit-summary.skeleton h4 {
    font-size: 1em;
    color: #323232;
    margin-bottom: 1.5rem;
}

.audit-summary.skeleton .files-to-audit,
.audit-summary.skeleton .lines-of-code {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 8px;
}

.audit-summary.skeleton .bouncing-lines {
    display: flex;
    gap: 2px;
    min-width: 60px;
}

.audit-summary.skeleton .bouncing-lines span {
    display: inline-block;
    font-size: 18px;
    font-weight: bold;
    color: #32AFC3;
    animation: bounce 1s ease-in-out infinite;
}

.audit-summary.skeleton label {
    font-size: 1rem;
    color: #323232;
}

.audit-summary.skeleton .processing-message {
    font-size: 0.9em;
    color: #8D8D8D;
    margin-top: 1.5rem;
    line-height: 1.4;
    font-style: italic;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

.audit-summary.skeleton .bouncing-lines span:nth-child(1) {
    animation-delay: 0s;
}

.audit-summary.skeleton .bouncing-lines span:nth-child(2) {
    animation-delay: 0.1s;
}

.audit-summary.skeleton .bouncing-lines span:nth-child(3) {
    animation-delay: 0.2s;
}

.audit-scope-selection.skeleton .p-treetable {
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 20px;
}

.audit-scope-selection.skeleton .p-treetable-tbody > tr > td {
    padding: 0.75rem 1rem;
}

.audit-scope-selection.skeleton .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.audit-scope-selection.skeleton .header-text {
    margin-bottom: 2rem;
}

.audit-scope-selection.skeleton .comments-container {
    margin-top: 2rem;
}

/* Match the actual component's row animation */
.audit-scope-selection.skeleton .p-treetable-tbody > tr {
    animation: rowFade 1s infinite alternate;
}

@keyframes rowFade {
    from {
        opacity: 0.7;
    }
    to {
        opacity: 1;
    }
}

/* Ensure the sidebar stays fixed */
.p-sidebar {
    position: fixed !important;
}
