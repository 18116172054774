.admin-panel {
    padding: 20px;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    margin-top: 20px;
    padding-left: 45px;
}

.grid-item {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    overflow: hidden;
}

.grid-item .p-card {
    height: 100%;
}

.grid-item .p-card-title {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.grid-item h2 {
    font-size: 2.5rem;
    text-align: center;
    margin: 20px 0;
}

.time-period-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.time-period-buttons .p-button {
    margin: 0 5px;
}

.time-period-buttons .p-button.p-button-outlined {
    background-color: #f0f0f0;
}

.error-message {
    color: #ff0000;
    text-align: center;
    padding: 20px;
}

.p-progress-spinner {
    width: 50px;
    height: 50px;
    margin: 0 auto;
    display: block;
}