/* Base styles */
.manage-access {
    width: 95%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.manage-access > * {
    box-sizing: border-box;
    align-self: flex-start;
}

/* Typography */
.manage-access h2 {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.manage-access h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
}

/* Select Privacy Section */
.select-privacy-section {
    display: flex;
    flex-wrap: nowrap; /* Prevent wrapping */
    align-items: center;
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.select-privacy-section .p-button {
    flex: 1; /* Allow buttons to grow and shrink equally */
    min-width: 200px; /* Ensure buttons don't get too small */
    max-width: 300px; /* Limit maximum width */
}

.select-privacy-section .p-button.p-button-success {
    background-color: #F9F9F9;
    border-color: #F9F9F9;
}

.select-privacy-section .p-button.p-button-outlined.p-button-secondary {
    background-color: #2CB392;
    border-color: #2CB392;
    color: white;
}

.select-privacy-section .p-button.p-button-outlined.p-button-secondary:not(:disabled):hover {
    background-color: #2CB392;
    color: #1f2937;
    border-color: #9ca3af;
}

/* Invited Auditors Section */
.invited-auditors-section {
    width: 100%;
    max-width: 800px;
}

.invited-auditors-section .p-datatable {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.invited-auditors-section .p-datatable .p-datatable-thead > tr > th {
    background-color: #F9F9F9;
    color: #1f2937;
    font-weight: 600;
    padding: 0.75rem 1rem;
}

.invited-auditors-section .p-datatable .p-datatable-tbody > tr > td {
    padding: 0.5rem 1rem;
}

.invited-auditors-section .p-datatable .p-datatable-tbody > tr:nth-child(even) {
    background-color: #F9F9F9;
}

.invited-auditors-section .p-column-title {
    font-weight: 600;
}

/* Status styles */
.invited-auditors-status {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.875rem;
}

.invited-auditors-status.pending {
    background-color: #FEF3C7;
    color: #92400E;
}

.invited-auditors-status.rejected {
    background-color: #FEE2E2;
    color: #991B1B;
}

.invited-auditors-status.has-access {
    background-color: #D1FAE5;
    color: #065F46;
}

/* Custom Invited Auditors Table */
.custom-invited-auditors-table .p-datatable-tbody > tr > td,
.custom-invited-auditors-table .p-datatable-thead > tr > th {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
}

.custom-invited-auditors-table .p-datatable-tbody > tr {
    height: 40px;
}

.custom-invited-auditors-table .p-paginator {
    font-size: 0.875rem;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .manage-access {
        width: 100%;
        padding: 0.5rem;
    }

    .select-privacy-section {
        justify-content: center;
    }

    .select-privacy-section .p-button {
        font-size: 0.875rem; /* Slightly smaller font for mobile */
        padding: 0.5rem; /* Adjust padding for mobile */
    }

    .invited-auditors-section {
        overflow-x: auto;
    }

    .custom-invited-auditors-table .p-datatable-tbody > tr > td,
    .custom-invited-auditors-table .p-datatable-thead > tr > th {
        padding: 0.25rem 0.5rem;
    }
}

@media screen and (max-width: 480px) {
    .manage-access h2 {
        font-size: 1.25rem;
    }

    .manage-access h4 {
        font-size: 0.875rem;
    }

    .invited-auditors-status {
        font-size: 0.75rem;
        padding: 0.2rem 0.3rem;
    }

    .select-privacy-section {
        flex-direction: column; /* Stack buttons vertically on very small screens */
        align-items: stretch;
    }

    .select-privacy-section .p-button {
        max-width: none; /* Allow full width on very small screens */
    }
}

/* Standardized Button Styles */
.invite-button,
.privacy-button {
    background-color: #2CB392;
    border-color: #2CB392;
    color: white;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;  /* Space between icon and text */
    min-width: 200px;
    margin-bottom: 2rem;
}

.invite-button.disabled,
.privacy-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.invite-button .p-button-label,
.privacy-button .p-button-label {
    color: white !important;
    font-weight: bold !important;
}

.invite-button .p-button-icon,
.privacy-button .p-button-icon {
    color: white !important;
    font-size: 1rem;
}

.invite-button .pi-spin,
.privacy-button .pi-spin {
    font-size: 1rem;
}

/* Override for selected privacy button */
.privacy-button.p-button-success {
    background-color: #F9F9F9 !important;
    border-color: #F9F9F9 !important;
    color: #2CB392 !important;
}

.privacy-button.p-button-success .p-button-label,
.privacy-button.p-button-success .p-button-icon {
    color: #2CB392 !important;
}