.contact-sales-page {
  position: relative;
  min-height: 100vh;
}

/* Header Section */
.contact-sales-header {
  background: linear-gradient(135deg, rgba(141, 231, 245, 0.21) 0%, rgba(240, 210, 245, 0.8) 100%);
  padding: 120px 0 180px;
  position: relative;
  overflow: hidden;
}

.contact-sales-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.contact-sales-header-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  color: #1C2B40;
}

.contact-sales-header-content h1 {
  font-size: 3.5rem;
  font-weight: 600;
  margin: 0 0 24px 0;
  line-height: 1.2;
}

.contact-sales-header-content p {
  font-size: 1.5rem;
  line-height: 1.6;
  margin: 0;
  font-weight: 400;
}

/* Content Section */
.contact-sales-content {
  margin-top: -100px;
  position: relative;
  padding-bottom: 120px;
}

.content-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: start;
  margin-top: 80px;
}

/* Customer Stories */
.customer-story {
  background: transparent;
  padding: 0;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 150px;
}

.customer-logo {
  height: 50px;
  width: auto;
  object-fit: contain;
}

.customer-quote {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #1C2B40;
  margin: 0;
  font-weight: 400;
  border-top: 2px solid #32AFC3;
  padding-top: 24px;
}

.customer-author {
  font-size: 1rem;
  color: rgba(28, 43, 64, 0.8);
  margin: 0;
}

/* Contact Form */
.contact-form-container {
  background: white;
  border-radius: 24px;
  padding: 48px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  order: 2;
}

.contact-form-content {
  max-width: 500px;
}

.form-instruction {
  font-size: 1rem;
  color: #1C2B40;
  margin: 0 0 32px 0;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-field {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-field label {
  font-size: 1rem;
  color: #1C2B40;
  font-weight: 500;
}

.form-field input,
.form-field select,
.form-field textarea {
  padding: 12px 16px;
  border: 1px solid rgba(28, 43, 64, 0.2);
  border-radius: 6px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  font-family: inherit;
}

.form-field select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

.form-field textarea {
  resize: vertical;
  min-height: 100px;
}

.form-field input:focus,
.form-field select:focus,
.form-field textarea:focus {
  outline: none;
  border-color: #32AFC3;
}

.field-required {
  font-size: 0.875rem;
  color: rgba(28, 43, 64, 0.6);
  margin-top: -4px;
}

.privacy-notice {
  font-size: 0.875rem;
  color: rgba(28, 43, 64, 0.8);
  line-height: 1.5;
  margin: 0;
}

.form-field input.error,
.form-field select.error,
.form-field textarea.error {
  border-color: #ff4d4f;
}

.field-info {
  display: flex;
  justify-content: flex-end;
  min-height: 20px;
  margin-top: 4px;
}

.char-count {
  font-size: 0.75rem;
  color: rgba(28, 43, 64, 0.6);
  text-align: right;
}

.error-message {
  color: #ff4d4f;
  font-size: 0.875rem;
  margin-top: 4px;
  text-align: right;
}

.submit-button {
  background: #32AFC3;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 14px 28px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: opacity 0.3s ease;
  margin-top: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-button:hover:not(:disabled) {
  opacity: 0.9;
}

.submit-button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.submit-button i {
  font-size: 1rem;
  color: white;
}

.submit-button span {
  color: white;
}

/* Required field indicator */
.form-field label[for]:after {
  content: " *";
  color: #ff4d4f;
}

/* Testimonials Section */
.testimonials-section {
  background: white;
  padding: 80px 0;
}

/* FAQ Section */
.faq-section {
  background: #f8f9fa;
  padding: 80px 0;
}

.faq-title {
  font-size: 2.5rem;
  color: #1C2B40;
  margin: 0 0 48px 0;
  font-weight: 600;
}

.faq-category {
  margin-bottom: 48px;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.faq-header h3 {
  font-size: 1.5rem;
  color: #1C2B40;
  margin: 0;
  font-weight: 600;
}

.show-all-button {
  background: none;
  border: none;
  color: #32AFC3;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s ease;
}

.show-all-button:hover {
  color: #2b97a8;
  background: none;
  border: none;
}

/* PrimeReact Accordion Customization */
.p-accordion .p-accordion-header .p-accordion-header-link {
  padding: 1.25rem;
  border: none;
  background: white;
  color: #1C2B40;
  font-weight: 500;
  border-radius: 8px;
  margin-bottom: 8px;
  transition: background-color 0.3s ease;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: white;
  color: #1C2B40;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.p-accordion .p-accordion-content {
  background: #f8f9fa;
  padding: 1.25rem;
  border: none;
  color: rgba(28, 43, 64, 0.8);
  margin-bottom: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  background: rgba(50, 175, 195, 0.05);
}

/* Media Queries */
@media (max-width: 1024px) {
  .contact-sales-header {
    padding: 100px 0 160px;
  }

  .contact-sales-header-content h1 {
    font-size: 3rem;
  }

  .contact-sales-header-content p {
    font-size: 1.3rem;
  }

  .content-grid {
    gap: 60px;
  }
}

@media (max-width: 768px) {
  .contact-sales-header {
    padding: 80px 0 140px;
  }

  .contact-sales-header-content h1 {
    font-size: 2.5rem;
  }

  .contact-sales-header-content p {
    font-size: 1.2rem;
  }

  .content-grid {
    grid-template-columns: 1fr;
    gap: 40px;
  }

  .contact-form-container {
    order: 1;
  }

  .contact-form-container,
  .customer-story {
    padding: 32px;
  }

  .testimonials-section {
    padding: 60px 0;
  }

  .customer-quote {
    font-size: 1.25rem;
  }

  .faq-section {
    padding: 60px 0;
  }

  .faq-title {
    font-size: 2rem;
    margin-bottom: 32px;
  }

  .faq-header h3 {
    font-size: 1.25rem;
  }
}

@media (max-width: 480px) {
  .contact-sales-header {
    padding: 60px 0 120px;
  }

  .contact-sales-header-content h1 {
    font-size: 2rem;
  }

  .contact-sales-header-content p {
    font-size: 1.1rem;
  }

  .contact-form-container,
  .customer-story {
    padding: 24px;
  }

  .testimonials-section {
    padding: 40px 0;
  }

  .customer-quote {
    font-size: 1.1rem;
  }

  .faq-section {
    padding: 40px 0;
  }

  .faq-title {
    font-size: 1.75rem;
  }
}
