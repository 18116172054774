* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
  }

.p-sidebar-header .p-sidebar-close {
    display: none;
}

  
.confirm-button {
    margin-top: 50px;
    width: 95%;
}

.confirm-button .p-button-label {
    font-weight: bold;      
}

.audit-summary {
    margin-top: 45px;
    padding-left: 10px;
}

.files-to-audit, .lines-of-code {
    margin-bottom: 10px;
}

.audit-order{
    margin-top: 50px;
}

.start-audit-button {
    margin-top: 15px;
    margin-left: -5px;
    width: 100%; 
    background: #2CB392;
}

.start-audit-button .p-button-label {
    color: #fff;     
    font-weight: bold;      
}

.start-audit-button:hover .p-button-label {
    color: #343A40;
    border-color: #2CB392;
}

.audit-info-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-right: 10px;
}

.audit-info-row-small {
    font-size: 12px;
    font-style: italic;
    color: #8D8D8D;
}

.audit-value {
    font-weight: bold;
}

.audit-divider {
    border: 0.5px solid #ccc; /* Grey thin line */
    margin: 10px 10px 10px 0px;
}

.audit-warning {
    color: #E83636;
    margin-top: 10px;
}

.start-audit-button.disabled {
    background-color: #ccc; /* Example styling for disabled button */
}

.talk-to-sales-button:hover {
    background-color: transparent !important;
    color: #323232 !important;
}

.talk-to-sales-button:hover .p-button-icon {
    color: #323232 !important;
}

.talk-to-sales-button .p-button-label {
    color: #fff !important;
}

.talk-to-sales-button .p-button-icon {
    color: #fff !important;
}