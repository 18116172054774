.profile-card {
    background: white;
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.profile-header {
    margin-bottom: 2rem;
}

.profile-header h2 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 0.5rem;
}

.profile-subtitle {
    font-size: 0.875rem;
    color: #6B7280;
    margin: 0;
}

.profile-edit-form,
.profile-view {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.profile-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.profile-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
}

.profile-value {
    font-size: 1rem;
}

.p-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.p-field label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
}

.p-field .p-inputtext,
.p-field .p-dropdown {
    background: #F9FAFB;
    border: 1px solid #D1D5DB;
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.p-field .p-inputtext:hover,
.p-field .p-dropdown:hover {
    border-color: #9CA3AF;
}

.p-field .p-inputtext:focus,
.p-field .p-dropdown:focus {
    border-color: #2CB392;
    box-shadow: 0 0 0 2px rgba(44, 179, 146, 0.2);
}

.p-field .p-inputtext:disabled {
    background: #F3F4F6;
    color: #6B7280;
    cursor: not-allowed;
}

/* Error states */
.p-field .p-inputtext.p-invalid {
    border-color: #EF4444;
}

.p-field .p-inputtext.p-invalid:hover {
    border-color: #DC2626;
}

.p-field .p-inputtext.p-invalid:focus {
    border-color: #EF4444;
    box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.2);
}

.p-field .p-error {
    color: #DC2626;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    display: block;
}

.profile-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #E5E7EB;
}

.profile-actions .p-button {
    font-size: 0.875rem;
    padding: 0.5rem 1rem;
}

.profile-actions .p-button-text {
    color: #6B7280;
}

.profile-actions .p-button-text:hover {
    background: #F3F4F6;
    color: #374151;
}

/* Profile card specific button styles */
.profile-card .profile-actions .p-button:not(.p-button-text) {
    background: #2CB392;
    border: none;
    color: white;
}

.profile-card .profile-actions .p-button:not(.p-button-text):hover {
    background: #229478;
}

.profile-card .profile-actions .p-button:not(.p-button-text):focus {
    box-shadow: 0 0 0 2px rgba(44, 179, 146, 0.2);
}

.profile-card .profile-actions .p-button.p-button-loading {
    background: #2CB392;
    opacity: 0.7;
}

/* Dropdown specific styles */
.p-dropdown-panel {
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
}

.p-dropdown-panel .p-dropdown-item {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    color: #374151;
    transition: all 0.2s ease;
}

.p-dropdown-panel .p-dropdown-item:hover {
    background: #F3F4F6;
}

.p-dropdown-panel .p-dropdown-item.p-highlight {
    background: #ECFDF5;
    color: #2CB392;
}

/* Toast styles */
.p-toast {
    opacity: 0.95;
}

.p-toast .p-toast-message {
    border-radius: 6px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Toast variants */
.p-toast .p-toast-message.p-toast-message-warn {
    background: #FEF3C7;
    border: 1px solid #F59E0B;
    color: #92400E;
}

.p-toast .p-toast-message.p-toast-message-error {
    background: #FEE2E2;
    border: 1px solid #EF4444;
    color: #991B1B;
}

.p-toast .p-toast-message.p-toast-message-success {
    background: #ECFDF5;
    border: 1px solid #2CB392;
    color: #065F46;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .profile-card {
        padding: 1rem;
    }

    .profile-actions {
        flex-direction: column-reverse;
        gap: 0.5rem;
    }

    .profile-actions .p-button {
        width: 100%;
    }
} 