/* Assuming there's a base CSS rule for .input-section-container */
.input-section-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    width: 100%;
    max-width: 800px;
}

.p-input-icon-right {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 300px;
}

.input-progress-container {
    position: relative;
    width: 100%;
}

.progress-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(50, 175, 195, 0.3); /* #32AFC3 with 0.3 opacity */
    transition: width 0.5s ease-in-out;
    pointer-events: none;
    border-radius: 4px;
}

.status-message {
    color: #8D8D8D;
    font-size: 0.8rem;
    margin-top: 0.5rem;
    text-align: left;
}

/* Ensure input maintains its style when disabled */
.p-inputtext:disabled {
    opacity: 1;
    background-color: #ffffff;
    color: #323232;
}

.add-repo-button {
    min-width: 120px;
    height: 40px;
    align-self: flex-start;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
    .input-section-container {
        flex-direction: column;
        align-items: stretch;
    }

    .p-input-icon-right {
        width: 100%;
    }

    .add-repo-button {
        width: 100%;
        max-width: 200px;
    }
}

/* Repository input section container */
.repository-input-section {
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    padding-left: 5%;
}

.header-container {
    margin-bottom: 15px;
    font-size: 1.1rem;
}
