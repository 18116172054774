.files-statistics-container {
    width: 100%;
    background-color: #F9F9F9;
}

.files-statistics-content-wrapper {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
}

.files-statistics-controls {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 1.5rem;
}

.files-statistics-search-section {
    flex: 1;
    max-width: 300px;
}

.files-statistics-search-wrapper {
    position: relative;
    display: block;
    width: 100%;
    max-width: 300px;
}

.files-statistics-search-wrapper .pi-search {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translateY(-50%);
    color: #6c757d;
    font-size: 0.875rem;
    z-index: 1;
}

.files-statistics-search-input {
    width: 100%;
    padding: 0.75rem 0.75rem 0.75rem 2.5rem;
    border: 1px solid #ced4da;
    border-radius: 6px;
    font-size: 0.875rem;
    color: #495057;
    transition: all 0.2s;
}

.files-statistics-search-input:focus {
    border-color: #32AFC3;
    box-shadow: 0 0 0 0.2rem rgba(50, 175, 195, 0.25);
}

.files-statistics-search-input::placeholder {
    color: #6c757d;
    font-size: 0.875rem;
}

.files-statistics-filter-section {
    flex: 2;
}

.files-statistics-filter-group,
.files-statistics-order-group {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.files-statistics-section-label {
    color: #6c757d;
    font-size: 0.875rem;
    font-weight: 600;
    white-space: nowrap;
}

.files-statistics-filter-controls {
    display: flex;
    gap: 1rem;
    flex: 1;
}

.files-statistics-filter-dropdown,
.files-statistics-order-dropdown {
    flex: 1;
}

.files-statistics-filter-dropdown .p-dropdown,
.files-statistics-order-dropdown .p-dropdown {
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 6px;
}

.files-statistics-filter-dropdown .p-dropdown-label,
.files-statistics-order-dropdown .p-dropdown-label {
    padding: 0.75rem;
    font-size: 0.875rem;
    color: #495057;
    box-shadow: none;
    border: none;
    position: relative;
    top: -5px;
}

.files-statistics-filter-dropdown .p-dropdown-label.p-placeholder,
.files-statistics-order-dropdown .p-dropdown-label.p-placeholder {
    color: #6c757d;
    box-shadow: none;
    border: none;
    position: relative;
    top: -5px;
}

.files-statistics-filter-dropdown .p-dropdown-filter-container input.p-dropdown-filter.p-inputtext,
.files-statistics-order-dropdown .p-dropdown-filter-container input.p-dropdown-filter.p-inputtext {
    width: 97% !important;
    padding: 0.5rem 0.75rem !important;
    margin: 0.2rem !important;
    font-size: 0.875rem !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.files-statistics-filter-dropdown .p-dropdown-filter-container,
.files-statistics-order-dropdown .p-dropdown-filter-container {
    padding: 0.5rem !important;
    border-bottom: 1px solid #e9ecef !important;
    position: relative !important;
}

.files-statistics-filter-dropdown .p-dropdown-filter-icon,
.files-statistics-order-dropdown .p-dropdown-filter-icon {
    position: absolute !important;
    right: 0.75rem !important;
    top: 53% !important;
    transform: translateY(-50%) !important;
    color: #6c757d !important;
}

.files-statistics-filter-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item,
.files-statistics-order-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item {
    padding: 0.75rem 1.25rem !important;
    font-size: 0.875rem !important;
    color: #495057 !important;
    transition: all 0.2s !important;
    margin: 0.25rem 0 !important;
}

.files-statistics-filter-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item:hover,
.files-statistics-order-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item:hover {
    background-color: rgba(50, 175, 195, 0.1) !important;
    color: #32AFC3 !important;
}

.files-statistics-filter-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item.p-highlight,
.files-statistics-order-dropdown .p-dropdown-items-wrapper .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: rgba(50, 175, 195, 0.1) !important;
    color: #32AFC3 !important;
    font-weight: 500 !important;
}

/* Ensure proper spacing in the dropdown panel */
.files-statistics-filter-dropdown .p-dropdown-panel .p-dropdown-items-wrapper,
.files-statistics-order-dropdown .p-dropdown-panel .p-dropdown-items-wrapper {
    padding: 0.5rem !important;
}

.files-statistics-filter-dropdown .p-dropdown-items,
.files-statistics-order-dropdown .p-dropdown-items {
    padding: 0.5rem 0;
}

.files-statistics-filter-dropdown .p-dropdown-item,
.files-statistics-order-dropdown .p-dropdown-item {
    padding: 0.75rem 1.25rem;
    font-size: 0.875rem;
    color: #495057;
    transition: all 0.2s;
    margin: 0.25rem 0;
}

.files-statistics-filter-dropdown .p-dropdown-item:first-child,
.files-statistics-order-dropdown .p-dropdown-item:first-child {
    margin-top: 0;
}

.files-statistics-filter-dropdown .p-dropdown-item:last-child,
.files-statistics-order-dropdown .p-dropdown-item:last-child {
    margin-bottom: 0;
}

.files-statistics-filter-dropdown .p-dropdown-item:hover,
.files-statistics-order-dropdown .p-dropdown-item:hover {
    background-color: rgba(50, 175, 195, 0.1);
    color: #32AFC3;
}

.files-statistics-filter-dropdown .p-dropdown-item.p-highlight,
.files-statistics-order-dropdown .p-dropdown-item.p-highlight {
    background-color: rgba(50, 175, 195, 0.1);
    color: #32AFC3;
    font-weight: 500;
}

/* Override PrimeReact's default focus styles */
.files-statistics-filter-dropdown .p-dropdown-item:focus,
.files-statistics-order-dropdown .p-dropdown-item:focus {
    box-shadow: none;
    outline: none;
}

.files-statistics-filter-dropdown .p-dropdown-panel,
.files-statistics-order-dropdown .p-dropdown-panel {
    border: 1px solid #ced4da;
    border-radius: 6px;
    margin-top: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.files-statistics-filter-dropdown .p-dropdown-items-wrapper,
.files-statistics-order-dropdown .p-dropdown-items-wrapper {
    padding: 0.5rem;
}

.files-statistics-order-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.files-statistics-order-listbox {
    width: 100%;
}

.files-statistics-order-listbox .p-listbox-list {
    padding: 0.5rem 0;
}

.files-statistics-order-listbox .p-listbox-item {
    padding: 0.75rem 1rem;
    color: #495057;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.files-statistics-order-listbox .p-listbox-item:hover {
    background: #e9ecef;
    cursor: pointer;
}

.files-statistics-order-listbox .p-listbox-item.p-highlight {
    background: #e3f2fd;
    color: #1976d2;
}

.files-statistics-order-listbox .p-listbox-item[data-pr-value*="desc"]::after {
    content: "↓";
    margin-left: 0.5rem;
    color: #6c757d;
}

.files-statistics-order-listbox .p-listbox-item[data-pr-value*="asc"]::after {
    content: "↑";
    margin-left: 0.5rem;
    color: #6c757d;
}

.files-statistics-order-listbox .p-listbox-item[data-pr-value^="perf"] {
    border-top: 1px solid #e9ecef;
}

.files-statistics-table {
    margin-top: 1rem;
}

.files-statistics-table .p-datatable-wrapper {
    border-radius: 6px;
    border: 1px solid #e9ecef;
}

.files-statistics-table .p-datatable-header {
    background: #f8f9fa;
    border: none;
    padding: 1rem;
}

.files-statistics-file-name-container {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.files-statistics-file-name-link {
    color: #32AFC3;
    cursor: pointer;
    transition: color 0.2s ease;
    font-size: 0.9375rem;
}

.files-statistics-file-name-link:hover {
    color: #2a8fa0;
    text-decoration: underline;
}

.files-statistics-lines-of-code {
    color: #6c757d;
    font-size: 0.8125rem;
    font-weight: normal;
}

.files-statistics-score-bars-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem;
    background-color: #fafafa;
}

.files-statistics-score-bar-item {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.files-statistics-score-bars-row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
    align-items: center;
    padding: 8px 0;
    width: 100%;
}

.files-statistics-paginator {
    margin-top: 1rem;
    padding: 1rem 0;
    border-top: 1px solid #e9ecef;
}

/* Responsive adjustments */
@media screen and (max-width: 1400px) {
    .files-statistics-filter-controls {
        gap: 0.75rem;
    }
    
    .files-statistics-filter-listbox {
        min-width: 180px;
    }

    .files-statistics-score-bars-row {
        grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
    }
}

@media screen and (max-width: 1200px) {
    .files-statistics-controls {
        flex-direction: column;
        align-items: stretch;
        gap: 1.5rem;
        padding-bottom: 0rem;
    }

    .files-statistics-search-section {
        max-width: 300px;
    }

    .files-statistics-filter-controls {
        flex-direction: column;
    }

    .files-statistics-filter-group,
    .files-statistics-order-group {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }

    .files-statistics-filter-listbox {
        min-width: 160px;
    }

    .files-statistics-score-bars-row {
        grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
        gap: 8px;
    }

    .files-statistics-filter-dropdown,
    .files-statistics-order-dropdown {
        min-width: 160px;
    }
}

@media screen and (max-width: 992px) {
    .files-statistics-score-bars-row {
        grid-template-columns: repeat(auto-fit, minmax(70px, 1fr));
        gap: 6px;
    }
}

@media screen and (max-width: 768px) {
    .files-statistics-filter-controls {
        flex-direction: column;
        gap: 0.75rem;
    }

    .files-statistics-filter-listbox {
        width: 100%;
        min-width: 100%;
    }

    .files-statistics-score-bars-row {
        grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
        gap: 4px;
    }

    .files-statistics-filter-dropdown,
    .files-statistics-order-dropdown {
        width: 100%;
        min-width: 100%;
    }
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container .p-inputtext.p-dropdown-filter {
    width: 97% !important;
    padding: 0.1rem !important;
    margin: 0.2rem !important;
    font-size: 0.875rem !important;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container {
    padding: 0.5rem !important;
    border-bottom: 1px solid #e9ecef !important;
}

.p-dropdown-panel .p-dropdown-items-wrapper {
    padding: 0.5rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 0.75rem 1.25rem !important;
    font-size: 0.875rem !important;
    color: #495057 !important;
    transition: all 0.2s !important;
    margin: 0.25rem 0 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item:hover {
    background-color: rgba(50, 175, 195, 0.1) !important;
    color: #32AFC3 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background-color: rgba(50, 175, 195, 0.1) !important;
    color: #32AFC3 !important;
    font-weight: 500 !important;
}

/* Fix the search icon position */
.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-container .p-dropdown-filter-icon {
    position: absolute !important;
    right: 1rem !important;
    top: 53% !important;
    transform: translateY(-50%) !important;
    color: #6c757d !important;
}

.files-table-container {
    margin-bottom: 1rem;
}

.files-table-paginator {
    margin-top: 1rem;
}

/* Style for all paginator buttons */
.files-table-paginator .p-paginator-element {
    color: #32AFC3;
    transition: all 0.2s ease;
}

/* Style for active page number */
.files-table-paginator .p-highlight {
    background-color: rgba(50, 175, 195, 0.1) !important;
    color: #32AFC3 !important;
    font-weight: 600 !important;
}

/* Style for inactive page numbers */
.files-table-paginator .p-paginator-page:not(.p-highlight) {
    color: #32AFC3 !important;
    font-weight: normal !important;
}

/* Hover effect for inactive page numbers */
.files-table-paginator .p-paginator-page:not(.p-highlight):hover {
    background-color: rgba(50, 175, 195, 0.05) !important;
}

.score-distribution-section {
    margin-top: 2rem;
}

.score-distribution-title {
    font-size: 18px;
    font-weight: 600;
    color: #32AFC3;
    margin-bottom: 0.5rem;
}

.score-distribution-subtitle {
    font-size: 16px;
    color: #6c757d;
}

.files-statistics-active-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    min-height: 48px;
}

.files-statistics-active-filters .p-chip {
    background-color: #e3f2fd;
    color: #1976d2;
    border-radius: 16px;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    transition: all 0.2s ease;
}

.files-statistics-active-filters .p-chip:hover {
    background-color: #bbdefb;
}

.files-statistics-active-filters .p-chip .p-chip-remove-icon {
    color: #1976d2;
    margin-left: 0.5rem;
}

.files-statistics-active-filters .p-chip .p-chip-remove-icon:hover {
    color: #1565c0;
}

