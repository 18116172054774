.attribute-statistics {
  margin-top: 15px;
  margin-left: 20px;
  width: 85%;
  font-family: 'Open Sans', sans-serif;
  font-size: 0.875rem;
  color: #8D8D8D;
}

.stat-label {
  font-weight: normal;
  margin-right: 4px;
  font-size: 0.875rem;
  color: #8D8D8D;
}

.stat-separator {
  font-size: 0.875rem;
  color: #8D8D8D;
}

.stat-value {
  border-radius: 4px;
  font-weight: normal;
  font-size: 0.875rem;
}

.stat-green {
  color: #2CB392;
  font-size: 0.875rem;
}

.stat-orange {
  color: #DE7539;
  font-size: 0.875rem;
}

.stat-red {
  color: #E83636;
  font-size: 0.875rem;
}

.stat-grey {
  color: #8D8D8D;
  font-size: 0.875rem;
}

.stat-count {
  color: #8D8D8D;
  font-weight: 600;
  font-size: 0.875rem;
} 