.forgot-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.forgot-password-card {
    width: 100%;
    max-width: 400px;
    padding: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
}

.forgot-password-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.forgot-password-header h1 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.forgot-password-header h4 {
    color: #6c757d;
    font-size: 1rem;
    font-weight: normal;
    margin-bottom: 1rem;
}

.p-field {
    margin-bottom: 1.5rem;
}

.p-fluid .p-field .p-inputtext {
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
}

.p-float-label {
    position: relative;
}

.p-float-label label {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    color: #6c757d;
    font-size: 0.9rem;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label {
    top: 0;
    font-size: 0.75rem;
    background-color: white;
    padding: 0 0.25rem;
}

.reset-password-button {
    width: 100%;
    background: #2CB392;
    border: none;
    padding: 0.75rem;
    font-size: 1rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.reset-password-button:hover {
    background: #259a7d;
}

.p-invalid .p-inputtext {
    border-color: #E83636;
}

.p-error {
    color: #E83636;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

.jump-to-login {
    text-align: center;
    margin-top: 1.5rem;
}

.login-link a {
    color: #32afc3;
    text-decoration: none;
}

.login-link a:hover {
    text-decoration: underline;
}

.floating-message-password {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

@media (max-width: 480px) {
    .forgot-password-card {
        padding: 1.5rem;
    }

    .forgot-password-header h1 {
        font-size: 1.25rem;
    }
}