/* StyledPanel.css */
.custom-panel {
  margin-bottom: 20px;
}

.custom-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  color: #333;
  font-weight: bold;
  padding: 0 0 0 15px;
}

.custom-panel .p-panel-content {
  padding: 15px;
  background-color: #fff;
}

.custom-panel .p-panel-header .p-panel-header-icon {
  border: none; 
  width: 2rem;
  height: 2rem;
  color: #323232;
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
}

