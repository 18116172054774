/* Start.css */
.start-container {
    display: flex;
    flex-direction: column;
    min-height: 75vh;
  }
  
  .content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .start-heading {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .start-subheading {
    font-size: 18px;
    margin-bottom: 20px;
  }
  
  /* New styles for input container */
  .input-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .single-digit-input {
    width: 40px;
    height: 40px;
    font-size: 20px;
    text-align: center;
    margin: 0 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .input-invalid {
    border-color: #E83636;
  }
  
  .error-message {
    color: #E83636;
    margin-top: 10px;
  }