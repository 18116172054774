.pricing-page {
  min-height: 100vh;
  background-image: linear-gradient(100deg, #ffffff 0%, rgba(149, 219, 231, 0.2) 30%, rgba(243, 143, 252, 0.2) 100%);
}

/* Header Section */
.pricing-header {
  padding: 80px 0 40px;
}

.pricing-header-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  text-align: left;
}

.pricing-header h1 {
  font-size: 3rem;
  color: #1C2B40;
  margin: 0 0 24px 0;
  font-weight: 600;
  line-height: 1.2;
}

.pricing-header p {
  font-size: 1.2rem;
  color: #666;
  margin: 0;
  line-height: 1.6;
  max-width: 800px;
}

/* Content Section */
.pricing-content {
  padding: 40px 0 120px;
}

.pricing-cards {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
}

/* Pricing Cards */
.pricing-card {
  background: white;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  transition: transform 0.3s ease;
  min-height: 400px;
  display: flex;
  flex-direction: column;
}

.pricing-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
}

.pricing-card.gradient-1::before {
  background: linear-gradient(90deg, #32AFC3 0%, #7B96E6 100%);
}

.pricing-card.gradient-2::before {
  background: linear-gradient(90deg, #7B96E6 0%, #9F5FE9 100%);
}

.pricing-card.gradient-3::before {
  background: linear-gradient(90deg, #9F5FE9 0%, #F8A1D1 100%);
}

.pricing-card:hover {
  transform: translateY(-4px);
}

.pricing-card-content {
  padding: 32px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.pricing-card h2 {
  font-size: 2rem;
  color: #1C2B40;
  margin: 0 0 16px 0;
  font-weight: 600;
}

.pricing-card p {
  font-size: 1.1rem;
  color: #666;
  margin: 0 0 32px 0;
  line-height: 1.6;
  flex-grow: 1;
}

.pricing-card-button {
  background-color: #323232;
  color: white;
  border: 2px solid #323232;
  padding: 14px 28px;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
}

.pricing-card-button:hover {
  background-color: #4a4a4a;
  border: 2px solid #4a4a4a;
}

.pricing-card-unavailable {
  background: rgba(0, 0, 0, 0.05);
  color: #666;
  padding: 14px 28px;
  border-radius: 6px;
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  width: 100%;
}

/* Media Queries */
@media (max-width: 1024px) {
  .pricing-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .pricing-header h1 {
    font-size: 2.5rem;
  }
}

@media (max-width: 768px) {
  .pricing-cards {
    grid-template-columns: 1fr;
    max-width: 500px;
  }

  .pricing-header {
    padding: 60px 0 30px;
  }

  .pricing-header h1 {
    font-size: 2rem;
  }

  .pricing-header p {
    font-size: 1.1rem;
  }

  .pricing-card {
    min-height: 350px;
  }
}
