/* DependenciesGraph.css */
.node.file {
  fill: #32AFC3; /* Color for file nodes */
}

.node.package {
  fill: #343A40; /* Color for package nodes */
}

.link {
  stroke: #999;
  stroke-opacity: 0.6;
}


.graph-label {
  font-family: Arial, sans-serif;
  font-size: 12px;
  fill: white; 
  white-space: pre-wrap; 
  word-break: break-word;
  user-select: none;
}
