/* Custom TabMenu Styles */

/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  font-size: large;
  line-height: 1.6;
}

.tab-menu-container {
  margin-left: 55px;
  margin-top: 20px;
}

.p-tabmenu {
  display: flex;
  width: 100%;
}

.p-tabmenu .p-tabmenu-nav {
  background: transparent;
  border: none;
  padding: 0;
  width: 100%;
  display: flex;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
  margin: 0;
  border: none;
  background: transparent;
  flex: 1;
  position: relative;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  background: transparent;
  border: none;
  padding: 1rem;
  color: #8D8D8D;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  transition: color 0.2s;
  position: relative;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link:hover {
  background: transparent;
  color: #32AFC3;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link.active-tab {
  color: #32AFC3;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #dee2e6;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link.active-tab::after {
  background-color: #32AFC3;
}

.p-menuitem-icon {
  margin-right: 0.5rem;
  color: #32AFC3;
}

.p-menuitem-text {
  color: inherit;
}

/* Remove any existing ::after pseudo-elements on .p-tabmenuitem */
.p-tabmenu .p-tabmenu-nav .p-tabmenuitem::after {
  display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .tab-menu-container {
    margin-left: 0;
  }

  .mobile-tab-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
  }

  .mobile-tab-header .current-tab {
    font-weight: bold;
    color: #32AFC3;
    padding-left: 45px;
    display: flex;
    align-items: center;
  }

  .mobile-tab-header .current-tab i {
    margin-right: 10px;
  }

  .p-button.p-button-icon-only {
    width: 2.357rem;
    padding: 0.5rem 0;
  }

  .p-menu {
    width: 100%;
    max-width: 20rem;
  }

  .p-menuitem-link {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: #495057;
    transition: background-color 0.2s, box-shadow 0.2s;
  }

  .p-menuitem-icon {
    margin-right: 0.5rem;
  }

  .p-menuitem-text {
    line-height: 1;
  }

  .p-menuitem-link:hover {
    background-color: #e9ecef;
  }

  .p-menuitem-link.active-tab {
    background-color: #f8f9fa;
  }

  .p-menuitem-link.active-tab .p-menuitem-icon,
  .p-menuitem-link.active-tab .p-menuitem-text {
    color: #32AFC3;
  }

  .p-menuitem-link.active-tab .p-menuitem-text {
    padding-left: 45px;
  }
}