.account-container {
    display: flex;
    width: 100%;
    height: 100vh;
    padding: 20px;
    padding-left: 15%;
    padding-top: 5%;
    box-sizing: border-box;
    background: #F9F9F9;
}

.account-menu {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding-right: 20px;
    border-right: 1px solid #e0e0e0;
}

.account-menu .p-button {
    margin-bottom: 10px;
    justify-content: flex-start;
}

.account-menu .p-button-text {
    color: #495057;
}

.account-menu .p-button-text.p-button-primary {
    color: #2196F3;
    font-weight: bold;
}

.account-content {
    flex-grow: 1;
    padding-left: 20px;
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
    .account-container {
        flex-direction: column;
    }

    .account-menu {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid #e0e0e0;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .account-content {
        padding-left: 0;
    }
}