/* Full width wrapper */
.try-demo-wrapper {
    width: 100%;
    background-color: #fbfbfb;
    min-height: 100%;
    padding: 0;
    margin: 0;
}

/* Base container styles */
.try-demo-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: visible;
}

.try-demo-subtitle {
    color: var(--text-color-secondary);
    text-align: center;
    font-weight: normal;
    max-width: 80%;
    line-height: 1.5;
}

/* Language selection and carousel styles */
.carousel-container {
    width: 80%;
    margin: 0 auto 2rem;
}

.language-selection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.language-carousel {
    padding: 1rem;
}

.language-carousel .p-carousel-items-container {
    margin: 0 -0.5rem;
}

/* Language card styles */
.language-card {
    margin: 0.5rem;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
    animation: wiggleHint 2s ease-in-out 1s;
}

@keyframes wiggleHint {
    0% { transform: translateX(0); }
    25% { transform: translateX(10px); }
    50% { transform: translateX(0); }
    75% { transform: translateX(10px); }
    100% { transform: translateX(0); }
}

.language-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.language-card-content {
    padding: 1rem;
}

.language-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.language-icon {
    width: 32px;
    height: 32px;
    margin-right: 1rem;
}

.language-name {
    font-size: 1.2rem;
    font-weight: bold;
}

.language-description {
    color: var(--text-color-secondary);
    margin: 0;
    line-height: 1.4;
}

/* Code editor styles */
.code-editor-wrapper {
    width: 80%;
    min-width: 350px;
    margin: 0 auto 1.5rem;
    position: relative;
}

[class*="editor-"] {
    max-height: 70vh;
    overflow-y: auto;
    font-family: 'Consolas', 'Monaco', 'Andale Mono', 'Ubuntu Mono', monospace;
    font-size: 14px;
    line-height: 1.5;
    background: var(--surface-ground);
    color: var(--text-color);
}

/* Scrollbar styles */
[class*="editor-"] {
    scrollbar-width: thin;
    scrollbar-color: var(--primary-color);
}

[class*="editor-"]::-webkit-scrollbar {
    width: 8px;
}

[class*="editor-"]::-webkit-scrollbar-track {
    background: var(--surface-ground);
}

[class*="editor-"]::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    border-radius: 4px;
}

/* Editor focus states */
.p-editor-container:focus-within,
[class*="editor-"]:focus {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
}

.p-editor-container .p-editor-content {
    min-height: 320px;
}

/* Character count and submit button */
.character-count {
    position: absolute;
    bottom: 0.5rem;
    right: 1.5rem;
    font-size: 0.875rem;
    color: var(--text-color-secondary);
}

.submit-button-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

/* Loading state styles */
.p-button-primary:disabled {
    opacity: 0.7;
    cursor: not-allowed;
}

.p-progress-spinner {
    margin-left: 0.5rem;
}

/* Audit results styles */
.audit-results-container {
    margin-top: 2rem;
    padding: 1.5rem;
    background: var(--surface-card);
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.audit-invalid-code {
    text-align: center;
    padding: 2rem;
    background: var(--surface-hover);
    border-radius: 6px;
}

.audit-invalid-code h3 {
    color: var(--yellow-500);
    margin: 1rem 0;
}

/* Audit overview and categories */
.audit-overview {
    margin-bottom: 2rem;
}

.overall-score {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}

.score-value {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--primary-color);
}

.audit-categories {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    margin-bottom: 2rem;
}

.category-card {
    padding: 1rem;
    background: var(--surface-hover);
    border-radius: 4px;
}

/* Recommendations styles */
.recommendations {
    background: var(--surface-hover);
    padding: 1rem;
    border-radius: 4px;
}

.recommendations ul {
    list-style-type: none;
    padding: 0;
}

.recommendations li {
    padding: 0.5rem 0;
    border-bottom: 1px solid var(--surface-border);
}

.recommendations li:last-child {
    border-bottom: none;
}

/* Token colors for syntax highlighting */
.token.comment { color: #6a9955; }
.token.string { color: #ce9178; }
.token.number { color: #b5cea8; }
.token.keyword { color: #569cd6; }
.token.function { color: #dcdcaa; }
.token.operator { color: #d4d4d4; }
.token.class-name { color: #4ec9b0; }

/* Validation and analysis styles */
.validation-message-container {
    margin-bottom: 2rem;
}

.validation-message {
    background-color: var(--surface-card);
    border-left: 4px solid #2CB392;
}

.validation-message p {
    margin: 0;
    line-height: 1.6;
    color: var(--text-color);
}

.qualitative-analysis {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin: 2rem 0;
}

.analysis-card {
    background-color: var(--surface-card);
}

.analysis-card h3 {
    color: #32AFC3;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;
}

.analysis-card p {
    margin: 0;
    line-height: 1.6;
    color: var(--text-color-secondary);
}

/* Tags section */
.tags-section {
    margin-top: 2rem;
}

.tags-section h3 {
    color: var(--text-color);
    margin-bottom: 1rem;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.tag-badge {
    background-color: #32AFC3;
    color: var(--surface-card);
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    font-size: 0.9rem;
    display: inline-block;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .try-demo-container {
        padding: 1rem;
        width: 100%;
    }

    .try-demo-subtitle {
        max-width: 95%;
        font-size: 0.9rem;
    }

    .carousel-container,
    .code-editor-wrapper {
        width: 95%;
    }

    .qualitative-analysis {
        gap: 1rem;
    }

    .tag-badge {
        font-size: 0.8rem;
        padding: 0.4rem 0.8rem;
    }
}

@media screen and (max-width: 480px) {
    .language-description {
        font-size: 0.8rem;
    }
}

/* Submit button styles */
.analyze-button-demo {
  background-color: #2CB392;
  border-color: #2CB392;
  color: white;
  min-width: 150px;
  margin: 0 auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;  /* Space between icon and text */
  min-width: 200px;
}

.analyze-button-demo.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.analyze-button-demo .p-button-label {
  color: white !important;
  font-weight: bold !important;
}

.analyze-button-demo .p-button-icon {
  color: white !important;
  font-size: 1rem;  /* Adjust icon size if needed */
}

.analyze-button-demo .pi-spin {
  font-size: 1rem;
}

.loading-message-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1.5rem;
}

.loading-message {
    text-align: center;
    color: var(--text-color-secondary);
    font-weight: normal;
    animation: fadeIn 0.3s ease-in;
    margin: 0;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Demo CTA Section */
.demo-cta-section {
    position: relative;
    margin-top: 4rem;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('../../images/frontpage/gradient.png');
    background-size: cover;
    background-position: top center;
    box-sizing: border-box;
    overflow: hidden;
}

.demo-cta-container {
    position: relative;
    padding: 4rem 0;
    width: 100%;
    margin: 0;
}

.demo-cta-content {
    position: relative;
    width: 85%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 3rem 0;
    z-index: 5;
    text-align: center;
}

.demo-cta-content h2 {
    font-size: 2.5rem;
    color: #323232;
    margin: 0 0 24px 0;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 3rem;
}

.demo-repo-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    justify-content: center;
}

.demo-repo-card {
    background: white;
    border-radius: 12px;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    min-width: 300px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.demo-repo-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.demo-repo-logo {
    height: 40px;
    width: auto;
    object-fit: contain;
    margin-bottom: 0.5rem;
}

.demo-repo-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.demo-repo-link {
    color: #666;
    text-decoration: none;
    font-size: 0.9rem;
    word-break: break-all;
    display: block;
    transition: color 0.3s ease;
}

.demo-repo-link:hover {
    color: #32AFC3;
}

.demo-repo-stats {
    color: #32AFC3;
    margin: 0;
    font-size: 0.9rem;
    font-weight: 500;
}

@media screen and (max-width: 1200px) {
    .demo-cta-content {
        width: 90%;
        padding: 2rem;
    }

    .demo-repo-grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .demo-cta-content h2 {
        font-size: 2.2rem;
    }
}

@media screen and (max-width: 768px) {
    .demo-cta-content h2 {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .demo-repo-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .demo-cta-content {
        width: 95%;
        padding: 1.5rem;
    }

    .demo-repo-card {
        padding: 1.25rem;
        min-width: unset;
    }
}