.formatted-text {
  font-size: 14px;
  line-height: 1.6;
  color: #4B4B4B;
}

.formatted-text-line {
  margin-bottom: 8px;
}

.formatted-text-line.section-line {
  font-weight: 600;
  color: #323232;
  margin-top: 16px;
  margin-bottom: 12px;
}

.formatted-text-line.bullet-line {
  padding-left: 20px;
  position: relative;
}

.formatted-text-line.bullet-line::before {
  content: "•";
  position: absolute;
  left: 8px;
  color: #32AFC3;
}

.formatted-text-line.sub-bullet-line {
  padding-left: 40px;
  position: relative;
  font-size: 18px;
}

.formatted-text-line.sub-bullet-line::before {
  content: "◦";
  position: absolute;
  left: 28px;
  color: #32AFC3;
}

.formatted-text strong {
  color: #323232;
  font-weight: 600;
} 