.invite-to-audit-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
}

.invitation-card {
    border: solid 1px #2CB392;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
}

.invite-content {
    padding: 20px;
}

.invite-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.invite-text {
    color: #8D8D8D;
    font-style: italic;
    margin-bottom: 10px;
}

.invitation-input-field {
    width: 100%;
    max-width: 455px;
    margin-bottom: 20px;
    box-sizing: border-box;
    padding: 0.75rem;
}

.p-invalid {
    border: 1px solid #E83636 !important;
}

.field {
    margin-bottom: 1.5rem;
}

.button-container {
    margin-top: 1.5rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.p-message {
    margin-top: 0.5rem;
}

.send-invitation-button {
    background-color: #2CB392;
    border-color: #2CB392;
    color: white;
    width: 100%;
    max-width: 240px;
    min-width: 200px;
    margin: 0 auto;
    display: flex !important;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;  /* Space between icon and text */
}

.send-invitation-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.send-invitation-button .p-button-label {
    color: white !important;
    font-weight: bold !important;
}

.send-invitation-button .p-button-icon {
    color: white !important;
    font-size: 1rem;  /* Adjust icon size if needed */
}

.send-invitation-button .pi-spin {
    font-size: 1rem;
}

/* Updated responsive adjustments */
@media (max-width: 768px) {
    .invite-to-audit-container {
        padding: 20px 16px;
        margin-left: 60px;
        max-width: 100%;
    }

    .invitation-card {
        max-width: 100%;
        margin: 0;
        border-radius: 8px;
        margin-left: 2%;
    }

    .invite-content {
        padding: 16px;
    }

    .invite-title {
        font-size: 1.2rem;
        margin-bottom: 16px;
    }

    .button-container {
        flex-direction: column;
        width: 100%;
    }

    .send-invitation-button {
        max-width: 100%;
        width: 100%;
        padding: 0.75rem;
    }
}

/* For very small screens */
@media (max-width: 480px) {
    .invite-to-audit-container {
        padding: 16px 12px;
        margin-left: 50px;
    }

    .invite-content {
        padding: 12px;
    }

    .invite-title {
        font-size: 1.1rem;
    }

    .invite-text {
        font-size: 0.9rem;
    }

    .invitation-input-field {
        font-size: 16px;
    }
}