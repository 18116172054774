/* Navigation.css */

.sticky-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #ffffff;
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.codedd-megamenu {
    border: none;
    padding: 0.5rem 1rem;
    background-color: #ffffff;
    display: flex;
    align-items: center;
}

.codedd-megamenu .p-megamenu-root-list {
    gap: 2rem;
    flex-grow: 1;
}

.codedd-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-content > .p-menuitem-link {
    padding: 0.5rem 1rem;
}

.codedd-megamenu .p-megamenu-panel {
    padding: 1.5rem;
    margin-top: 0.2rem;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(255, 255, 255, 0.3);
    min-width: 900px; /* Increase total width */
}

/* Horizontal layout for megamenu grid */
.codedd-megamenu .p-megamenu-grid {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: 0 auto;
}

.codedd-megamenu .p-megamenu-submenu {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.megamenu-item {
    padding: 1rem;
    min-width: 280px;
    max-width: 320px;
}

/* Override PrimeReact default styles */
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-content > .p-menuitem-link,
.p-megamenu .p-megamenu-root-list > .p-menuitem > .p-menuitem-content > .p-menuitem-link:hover,
.p-megamenu .p-megamenu-root-list > .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link,
.p-megamenu .p-menuitem > .p-menuitem-content > .p-menuitem-link.p-link,
.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-content > .p-menuitem-link.p-link,
.p-megamenu .p-megamenu-panel .p-menuitem-link,
.p-link:hover,
.p-link {
    background: transparent !important;
    box-shadow: none !important;
}

.p-megamenu .p-menuitem.p-menuitem-active > .p-menuitem-content {
    background: transparent !important;
}

.p-megamenu .p-menuitem > .p-menuitem-content {
    background: transparent !important;
}

.logo-text {
    font-size: 1.25rem;
}

.logo-link {
    text-decoration: none;
    margin-right: 2rem;
}

.nav-logo {
    font-weight: bold;
    font-size: 1.5rem;
    color: #32AFC3;
}

.logo-link:hover .nav-logo {
    color: #32AFC3;
}

.nav-end-container {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.login-link {
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.login-link span {
    font-weight: bold;
    color: #323232;
}

.free-trial-button {
    text-decoration: none;
    padding: 0.5rem 1rem;
    background-color: #32AFC3;
    color: white;
    border-radius: 4px;
    font-weight: bold;
    margin-right: 1rem;
}

.free-trial-button:hover {
    background-color: #2B95A6;
}

.megamenu-item-link {
    text-decoration: none;
    color: inherit;
}

.megamenu-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #323232;
}

.megamenu-description {
    font-size: 14px;
    color: #8D8D8D;
    margin-bottom: 0.5rem;
    line-height: 1.4;
}

.megamenu-link {
    font-size: 14px;
    color: #323232;
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    transition: all 0.2s ease;
}

.megamenu-link i {
    transition: transform 0.2s ease, color 0.2s ease;
}

.megamenu-item-link:hover .megamenu-link {
    color: #32AFC3;
}

.megamenu-item-link:hover .megamenu-link i {
    transform: translateX(4px);
    color: #32AFC3;
}

/* Layout for megamenu items */
.p-megamenu-grid {
    display: flex;
    flex-wrap: nowrap;
}

.p-megamenu-col-6 {
    flex: 1;
}

body {
    margin: 0;
    padding: 0;
}

main {
    padding-top: 1rem; /* Add some space below the sticky header */
}

@media (max-width: 1000px) {
    .codedd-megamenu .p-megamenu-root-list {
        display: none;
    }

    .login-link {
        display: none;
    }

    .codedd-megamenu {
        display: none;
    }
    .free-trial-button {
        min-width: 142px;
        padding: 9px 15px 9px 15px;
    }
}

@media (max-width: 768px) {
    .codedd-megamenu {
        padding: 0.5rem 1rem;
    }

    .codedd-megamenu .p-megamenu-root-list {
        gap: 1rem;
    }

    .p-megamenu-grid {
        flex-wrap: wrap;
    }

    .p-megamenu-col-6 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .megamenu-description,
    .megamenu-link {
        font-size: 12px;
    }
}