.why-codedd-page {
  position: relative;
  min-height: 100vh;
}

/* Hero Section */
.why-codedd-hero {
  background: linear-gradient(40deg, #1C2B40 30%, #7a3493 120%);
  padding: 80px 0 0 0;
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
  min-height: 85vh;
}

.why-codedd-hero::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  background: linear-gradient(to bottom, transparent, #1C2B40);
  pointer-events: none;
}

.why-codedd-hero-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px 8rem 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  position: relative;
}

.why-codedd-hero-text {
  max-width: 800px;
  margin-bottom: 48px;
  position: relative;
  z-index: 2;
}

/* Hero Title Styles */
.why-codedd-hero .hero-title {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 0 8px 0;
}

.why-codedd-hero .gradient-wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
}

.why-codedd-hero .hero-gradient-text {
  font-size: 3.5rem;
  font-weight: 600;
  background: linear-gradient(
    45deg,
    #F8A1D1 0%,
    #9F5FE9 25%,
    #F8A1D1 50%,
    #9F5FE9 75%,
    #F8A1D1 100%
  );
  background-size: 400% 400%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: hero-gradient-flow 15s ease infinite;
  display: inline;
  white-space: nowrap;
}

.why-codedd-hero h1 {
  font-size: 3.5rem;
  color: white;
  margin: 0 0 24px 0;
  font-weight: 600;
  line-height: 1.2;
}

.why-codedd-hero .hero-description {
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 24px 0;
  line-height: 1.6;
  font-weight: 400;
}

/* Hero CTA */
.why-codedd-hero .hero-cta {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 48px 0;
  position: relative;
  z-index: 2;
}

.why-codedd-hero .cta-primary-button {
  box-sizing: border-box;
  min-width: 200px;
  padding: 14px 28px;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 6px;
  background-color: #32AFC3;
  color: white;
  border: 2px solid #32AFC3;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0;
}

.why-codedd-hero .cta-primary-button:hover {
  background-color: transparent;
  color: #32AFC3;
}

/* Background Numbers */
.background-numbers {
  position: absolute;
  top: 0;
  right: -10%;
  width: 50%;
  height: 100%;
  z-index: 1;
}

.floating-number {
  position: absolute;
  font-size: 12rem;
  font-weight: 700;
  transform-origin: top right;
}

.floating-number:nth-child(1) {
  color: rgba(255, 255, 255, 0.3);
  top: 8%;
  right: 30%;
}

.floating-number:nth-child(2) {
  color: rgba(255, 255, 255, 0.2);
  top: 30%;
  right: 20%;
}

.floating-number:nth-child(3) {
  color: rgba(255, 255, 255, 0.1);
  top: 52%;
  right: 10%;
}

.floating-number:nth-child(4) {
  color: rgba(255, 255, 255, 0.05);
  top: 74%;
  right: 5%;
}

@keyframes hero-gradient-flow {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Media Queries */
@media (max-width: 1200px) {
  .why-codedd-hero-text {
    max-width: 700px;
  }

  .why-codedd-hero .hero-gradient-text,
  .why-codedd-hero h1 {
    font-size: 3rem;
  }

  .floating-number {
    font-size: 16rem;
  }
}

@media (max-width: 768px) {
  .why-codedd-hero {
    padding: 60px 0 0 0;
    min-height: 70vh;
  }

  .why-codedd-hero-content {
    padding: 0 16px 6rem 16px;
  }

  .why-codedd-hero .hero-gradient-text,
  .why-codedd-hero h1 {
    font-size: 2.5rem;
  }

  .why-codedd-hero .hero-description {
    font-size: 1.2rem;
  }

  .floating-number {
    font-size: 12rem;
    right: 5%;
  }

  .floating-number:nth-child(1) {
    color: rgba(255, 255, 255, 0.1);
    top: 5%;
  }

  .floating-number:nth-child(2) {
    color: rgba(255, 255, 255, 0.08);
    top: 32%;
  }

  .floating-number:nth-child(3) {
    color: rgba(255, 255, 255, 0.05);
    top: 60%;
  }

  .floating-number:nth-child(4) {
    display: none;
  }


  .why-codedd-hero::after {
    height: 30vh;
  }
}

@media (max-width: 480px) {
  .why-codedd-hero {
    padding: 40px 0;
  }

  .why-codedd-hero .hero-gradient-text,
  .why-codedd-hero h1 {
    font-size: 2rem;
  }

  .why-codedd-hero .hero-description {
    font-size: 1.1rem;
  }

  .floating-number {
    font-size: 8rem;
  }

  .why-codedd-hero .cta-primary-button {
    width: 100%;
    min-width: unset;
  }
}

/* Reasons Section */
.reasons-section {
  background: #1C2B40;
  position: relative;
  overflow: hidden;
  margin-top: -50vh;
  padding-top: 50vh;
  min-height: 100vh;
}

.reasons-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  display: grid;
  grid-template-columns: 25% 75%;
  gap: 40px;
  position: relative;
}

/* Nav styles */
.reasons-nav-wrapper {
  position: sticky;
  overflow: hidden;
  height: 100vh;
}

.reasons-nav {
  position: sticky;
  top: 80px;
  padding-right: 20px;

}

/* Content styles */
.reasons-content {
  position: relative;
  padding-left: 40px;
  height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.reasons-content::-webkit-scrollbar {
  width: 8px;
}

.reasons-content::-webkit-scrollbar-track {
  background: transparent;
}

.reasons-content::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}

.reason-nav-item {
  margin-bottom: 32px;
  padding: 8px 0;
  cursor: pointer;
}

.reason-nav-item a,
.reason-nav-item a span {
  color: white !important;
}

.reason-nav-item a {
  display: flex;
  gap: 16px;
  text-decoration: none;
  font-size: 1.2rem;
  transition: all 0.3s ease;
  position: relative;
  width: fit-content;
}

.reason-nav-item .reason-number {
  font-weight: 400;
  min-width: 28px;
}

/* Active styles */
.reason-nav-item.active a,
.reason-nav-item.active .reason-number {
  font-weight: 600;
}

/* Underline effect */
.reason-nav-item a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  right: 0;
  background: linear-gradient( 45deg, #F8A1D1 0%, #9F5FE9 100%);
  transition: width 0.3s ease;
  transform-origin: left;
}

.reason-nav-item:hover a::after,
.reason-nav-item.active a::after {
  width: 100%;
}

.reason-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.reason-item .reason-number {
  font-size: 6rem;
  font-weight: 700;
  background: linear-gradient(270deg, #F8A1D1 80%, #6e2fb6 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reason-item h2 {
  font-size: 3rem;
  color: white;
  margin: 0 0 24px 0;
  font-weight: 600;
  line-height: 1.2;
}

.reason-item p {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
  margin: 0;
  line-height: 1.6;
  max-width: 600px;
}

.reason-item .quote {
  position: relative;
  margin-top: 48px;
  padding: 48px 32px 32px;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 6rem;
}

.reason-item .quote::before {
  content: '"';
  position: absolute;
  top: 8px;
  left: 24px;
  font-size: 6rem;
  font-weight: 700;
  background: linear-gradient(
    45deg,
    #F8A1D1 0%,
    #9F5FE9 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
}

.quote-company-logo {
  position: absolute;
  top: 10px;
  right: 10px;  /* Changed from left to right */
  width: auto;
  height: 50px;
  background: #1C2B40;
  border-radius: 8px;
  padding: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.quote-company-logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.quote-text {
  font-size: 1.1rem;
  color: white;
  font-style: italic;
  margin: 0 0 16px 0;  /* Adjusted margin */
  line-height: 1.6;
  position: relative;
  z-index: 1;
  margin-top: 20px;
}

.quote-author {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 4px;
  position: relative;
  z-index: 1;
}

.quote-role {
  color: rgba(255, 255, 255, 0.5);
  font-size: 0.8rem;
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .reasons-container {
    grid-template-columns: 1fr;
    padding-top: 40px;
  }

  .reasons-nav-wrapper {
    display: none;
  }

  .reasons-content {
    padding-left: 0;
  }

  .reason-item {
    min-height: auto;
    padding: 60px 0;
  }

  .reason-item h2 {
    font-size: 2rem;
  }

  .reason-item .reason-number {
    font-size: 4rem;
  }

  .reason-item p {
    font-size: 1.1rem;
  }
}

/* Remove the old transition gradient */
.reasons-section::before {
  display: none;
}

/* Add smooth scrolling to html */
html {
  scroll-behavior: smooth;
}

/* CTA Container */
.cta-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #1C2B40;
  padding: 60px 0;
  transform: translateY(100%);
  transition: transform 0.3s ease;
  z-index: 10;
  visibility: hidden;
}

.cta-container.visible {
  transform: translateY(0);
  visibility: visible;
}

.cta-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.cta-gradient-title {
  font-size: 3rem;
  color: white;
  margin-bottom: 48px;
  background: linear-gradient(45deg, #F8A1D1, #9F5FE9);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.cta-left p,
.cta-right p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  margin-bottom: 24px;
}

.pricing-link {
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.pricing-link:hover {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .cta-grid {
    grid-template-columns: 1fr;
    gap: 32px;
  }

  .cta-gradient-title {
    font-size: 2rem;
  }
}
