.footer {
    background-color: #1C2B40;
    color: white;
    padding: 40px 0 0 0;
    margin-top: auto;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.footer-main {
    display: flex;
    gap: 60px;
}

.footer-logo {
    flex-shrink: 0;
}

.footer-logo-img {
    height: 35px;
    width: auto;
}

.footer-columns {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    flex-grow: 1;
}

.footer-column h3 {
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.footer-column ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-column ul li {
    margin-bottom: 8px;
}

.footer-column ul li a {
    color: #8C8C8C;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.2s ease;
}

.footer-column ul li a:hover {
    color: white;
    text-decoration: none;
}

.footer-bottom {
    margin-top: 40px;
    padding: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-bottom-content {
    max-width: 1200px;
    margin: 0 auto;
    text-align: left;
    color: #8C8C8C;
    font-size: 14px;
}

.footer-bottom-text {
    color: white;
    font-size: 0.8rem;
}

/* Responsive design */
@media (max-width: 1024px) {
    .footer-columns {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }
}

@media (max-width: 768px) {
    .footer-main {
        flex-direction: column;
        gap: 30px;
    }

    .footer-logo {
        text-align: center;
    }

    .footer-columns {
        grid-template-columns: 1fr;
        gap: 30px;
    }

    .footer-content {
        padding: 0 15px;
    }

    .footer-column {
        margin-bottom: 20px;
    }

    .footer-bottom-content {
        text-align: center;
    }
}