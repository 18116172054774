/* General and layout styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.6;
    color: #323232;
}

.audit-scope-selection {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    margin-left: 65px;
    margin-right: 310px;
    margin-bottom: 20px;
    height: 100vh;
}

.header-text {
    text-align: left;
    margin-bottom: 20px;
}

.header-text h2 {
    font-size: 1.5em;
}

.header-text .sub-text {
    font-size: 0.9em;
    color: #8D8D8D;
}

.table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 15px;
}

/* TreeTable specific styles */
.p-treetable .p-treetable-thead > tr > th {
    background-color: #f4f4f4;
    color: #333;
    font-weight: bold;
    padding: 10px 20px;
    margin-top: 15px;
    border-bottom: 1px solid #ddd;
}

.p-treetable .p-treetable-tbody > tr {
    border-bottom: 1px solid #ddd;
}

.p-treetable .p-treetable-tbody > tr:nth-child(odd) {
    background-color: #fafafa;
}

.p-treetable .p-treetable-tbody > tr > td {
    padding: 10px 20px;
}

/* Style the cells in the 'Name' column specifically */
.p-treetable .p-treetable-tbody > tr > td.name-column {
    padding-left: 0;
    margin-left: 0;
}

/* New styles for folder formatting */
/* Arrow icon spacing */
.p-treetable .p-treetable-toggler-icon {
    margin-left: 5px;
}

/* Node name spacing */
.p-treetable .p-treetable-tbody > tr > td span {
    margin-left: 5px;
}

/* Folder-specific row styling */
.p-treetable .p-treetable-tbody > tr[data-p-type="Folder"] {
    background-color: #f8f9fa;
}

.name-column .p-treetable-tbody td:first-of-type {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name-column .p-treetable-tbody td:first-of-type span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

/* Non-selectable items styling */
.non-selectable-node {
    color: #9CA3AF;
}

.p-treetable-tbody tr td .non-selectable-node ~ .p-checkbox {
    display: none;
}

/* Style for size and type columns when row is non-selectable */
.p-treetable-tbody tr[class*='disabled'] td,
.p-treetable-tbody tr:has(.non-selectable-node) td {
    color: #9CA3AF;
}

/* Reset color for name column if needed */
.p-treetable-tbody tr:has(.non-selectable-node) td:first-child {
    color: inherit;
}


.p-checkbox {
    vertical-align: middle;
    height: 20px;
}

.custom-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #32AFC3;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    transition: all 0.2s ease;
}

.checkbox-box.checked {
    background-color: #32AFC3;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}

.checkbox-box.partial {
    background-color: #32AFC3;
}

.check-icon {
    width: 14px;
    height: 14px;
    vertical-align: middle;
}

.partial-indicator {
    width: 12px;
    height: 2px;
    background-color: white;
    vertical-align: middle;
}

.custom-checkbox.disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.custom-checkbox .checkbox-box.partial {
    background-color: #32AFC3;
    border-color: #32AFC3;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    transition: all 0.2s ease;
}

.custom-checkbox .checkbox-box.partial::after {
    content: '';
    position: absolute;
    left: 25%;
    top: 50%;
    width: 50%;
    height: 2px;
    background: white;
    transform: translateY(-50%);
}

/* Base checkbox styles */
.p-checkbox .p-checkbox-box {
    position: relative;
    width: 20px;
    height: 20px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #32AFC3;
    border-radius: 3px;
    transition: background-color 0.2s;
}



.p-checkbox .p-checkbox-box .p-checkbox-icon {
    width: 14px;
    height: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.p-checkbox.p-indeterminate .p-checkbox-box::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
}

.p-checkbox .p-checkbox-box.p-highlight {
    background-color: #32AFC3 !important;
    border-color: #32AFC3 !important;
}

/* Style the SVG icon */
.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon {
    background-color: #32AFC3;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon path {
    fill: white !important;
}

/* Normal checked state */
.p-checkbox .p-checkbox-box.p-highlight {
    background-color: #32AFC3 !important;
    border-color: #32AFC3 !important;
}

/* Indeterminate state */
.p-checkbox.p-indeterminate .p-checkbox-box,
.p-checkbox .p-checkbox-box[data-p-indeterminate="true"] {
    background-color: #32AFC3 !important;
    border-color: #32AFC3 !important;
}

.p-checkbox.p-indeterminate .p-checkbox-box::after,
.p-checkbox .p-checkbox-box[data-p-indeterminate="true"]::after {
    content: '';
    position: absolute;
    left: 4px;
    top: 50%;
    width: 12px;
    height: 2px;
    background: white;
    transform: translateY(-50%);
}

/* Disabled states */
.disabled-checkbox .p-checkbox.p-indeterminate .p-checkbox-box,
.disabled-checkbox .p-checkbox .p-checkbox-box[data-p-indeterminate="true"] {
    background-color: #ccc !important;
    border-color: #aaa !important;
}

.disabled-checkbox .p-checkbox.p-indeterminate .p-checkbox-box::after,
.disabled-checkbox .p-checkbox .p-checkbox-box[data-p-indeterminate="true"]::after {
    background: #777;
}

/* Hover states */
.p-checkbox:not(.p-checkbox-disabled).p-indeterminate .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box[data-p-indeterminate="true"]:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    background-color: #32AFC3 !important;
    border-color: #32AFC3 !important;
}

.p-checkbox.p-component.p-checkbox-disabled .p-checkbox-box {
    background-color: #ccc;
    border-color: #aaa;
}

.p-checkbox.p-component.p-checkbox-disabled .p-checkbox-box.p-highlight .p-checkbox-icon {
    color: #777;
}

.disabled-checkbox {
    opacity: 0.8;
    pointer-events: none;
}

.audit-scope-selection.disabled {
    opacity: 0.8;
}

.audit-scope-selection.disabled .p-treetable {
    pointer-events: none;
}

/* Style for non-selectable state */
.p-checkbox.p-disabled {
    opacity: 0.8;
}

/* Keep the tree structure visible but disabled */
.p-treetable-tbody tr.disabled td {
    opacity: 0.8;
}

/* Remove border and set transparent background */
.p-treetable .p-treetable-toggler {
    border: none;
    background-color: transparent;
    border-radius: 50%; /* Makes the button round */
}


/* Hide the default SVG icon */

/* Custom icon for collapsed state (using pi-folder) */
.p-treetable .p-treetable-toggler::before {
    content: "\e963"; /* Unicode for pi-folder */
    font-family: "PrimeIcons";
    font-size: 18px;
    color: #323232;
}

/* Custom icon for expanded state (using pi-folder-open) */
.p-treetable .p-treetable-tbody > tr > td .p-treetable-toggler.p-treetable-toggler-open::before {
    content: "\e964"; /* Unicode for pi-folder-open */
}

/* Remove hover effect and set cursor */
.p-treetable .p-treetable-toggler:hover {
    background-color: transparent; /* Removes any hover background color */
    cursor: pointer; /* Sets the cursor to pointer */
}

/* Style the search input */
.p-input-icon-right {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    max-width: 300px;
    width: 100%;
    transition: all 0.3s ease;
}

.p-input-icon-right input[type="search"] {
    width: 100%;
    padding: 0.75rem 2.5rem 0.75rem 1rem;
    border: 1px solid #ced4da;
    border-radius: 4px;
    font-size: 1rem;
    transition: all 0.3s ease;
    background-color: #ffffff;
}

.p-input-icon-right input[type="search"]:focus {
    outline: none;
    border-color: #32AFC3;
    box-shadow: 0 0 0 2px rgba(50, 175, 195, 0.25);
}

.p-input-icon-right input[type="search"]::placeholder {
    color: #6c757d;
    opacity: 0.8;
}

/* Responsive adjustments for the search field */
@media screen and (max-width: 768px) {
    .table-header {
        flex-direction: column;
        gap: 1rem;
        align-items: stretch;
    }

    .p-input-icon-right {
        max-width: 100%;
    }

    .p-input-icon-right input[type="search"] {
        font-size: 0.95rem;
        padding: 0.65rem 2.25rem 0.65rem 0.85rem;
    }
}

@media screen and (max-width: 480px) {
    .p-input-icon-right input[type="search"] {
        font-size: 0.9rem;
        padding: 0.6rem 2rem 0.6rem 0.75rem;
    }
}

/* Card container styles */
.card {
    border-radius: 4px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    padding: 20px;
    background-color: #fff;
}

.select-container {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.select-container label {
    margin-left: 8px;
}

.comments-container {
    padding-top: 30px;
    align-items: center;

}

.save_button {
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
}

.tree-table-container {
    margin-top: 1rem;
    background: white;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.p-treetable .p-treetable-tbody > tr {
    background: inherit;
}

.p-treetable .p-treetable-tbody > tr:hover {
    background: #f8f9fa;
}

.p-treetable .p-treetable-tbody > tr.folder {
    font-weight: 500;
}

.p-treetable .name-column {
    padding-left: 0.5rem;
}

.p-treetable .p-treetable-header {
    background: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem;
}

.p-treetable .p-treetable-thead > tr > th {
    background: #f8f9fa;
    color: #495057;
    font-weight: 600;
}