.user-dashboard-modal {
    width: 90%;
    min-width: auto;
    max-width: 1200px;
    height: auto;
    margin: 1rem;
}

.user-dashboard-modal .p-dialog-header {
    border-bottom: none;
}

.public-ssh-panel {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 1rem;
}

.ssh-key-text {
    font-family: monospace;
    background-color: #f5f5f5;
    padding: 1rem;
    border-radius: 4px;
    word-break: break-all; /* This will break the text at any point to ensure it fits within the container */
    white-space: pre-wrap; /* This will ensure the text wraps to the next line if it exceeds the container width */
    margin-bottom: 1rem;
}

button.validate-access-button {
    background-color: #2CB392;
    border-color: #2CB392;
    font-weight: bold;
    min-width: auto;
    width: 100%;
    max-width: 300px;
    padding: 0.75rem 1.5rem;
}

button.validate-access-button .p-button-label {
    color: white;
    font-weight: bold;
}

button.validate-access-button:hover {
    opacity: 0.8;
}

button.validate-access-button:disabled {
    background-color: #DFDFDF;
    border-color: #DFDFDF;
    color: #B3B3B3;
}

.error-message {
    color: #E83636;
    padding-top: 0.625rem;
}

.content-wrapper {
    padding: 1.25rem;
}

.title-container {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    flex-wrap: wrap;
}

.header-logo {
    width: 3.125rem;
    margin-right: 1rem;
}

.header-credentials-text, .header-credentials-text * {
    font-size: 1.5rem;
}

.instructions-key-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    width: 100%;
}

@media screen and (min-width: 925px) {
    .instructions-key-container {
        grid-template-columns: 6.5fr 1px 3.5fr;
    }
}

.ssh-instructions {
    padding: 0.3125rem;
}

.ssh-instructions h6 {
    font-weight: bold;
    margin-bottom: 8px;
}

.ssh-instructions ul {
    padding-left: 20px;
    list-style: none;
}

.vertical-separator {
    display: none;
    background-color: #32AFC3;
    width: 1px;
    min-height: 350px;
}

.horizontal-separator {
    display: block;
    background-color: #32AFC3;
    height: 1px;
    width: 100%;
    margin: 1.5rem 0;
}

@media screen and (min-width: 925px) {
    .vertical-separator {
        display: block;
    }
    
    .horizontal-separator {
        display: none;
    }
}

.public-ssh-container {
    user-select: text !important; /* Allow users to select the text */
    padding: 0.625rem;
    flex: 3.5;
    position: relative;
    overflow-x: auto;
}

.copy-button-container {
    margin-top: 10px; /* Add some space above the button */
    display: flex; /* This makes it easier to align the button to the left */
    align-items: center; /* Aligns items vertically in the center, if needed */
}

button.copy-btn {
    width: 100%;
    max-width: 230px;
}

.authenticate-footer {
    margin-top: 1.25rem;
    display: flex;
    justify-content: center;
}

.highlight {
    color: #32AFC3; /* Your chosen highlight color */
}