.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f5f5f5;
    padding: 20px;
}

.login-card {
    width: 100%;
    max-width: 400px; /* Slightly smaller than registration for a compact login form */
    padding: 2rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #ffffff;
}

.login-header {
    text-align: center;
    margin-bottom: 1.5rem;
}

.login-header h1 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.p-field {
    margin-bottom: 1.5rem;
}

.p-fluid .p-field .p-inputtext,
.p-fluid .p-field .p-password {
    font-size: 0.9rem;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 4px;
    max-width: 400px;
}

.p-fluid .p-field .p-password input {
    border: none;
    width: 100%;
    padding: 0.5rem 0.75rem;
}

.p-float-label {
    position: relative;
}

.p-float-label label {
    position: absolute;
    top: 50%;
    left: 0.75rem;
    transform: translateY(-50%);
    transition: all 0.2s ease;
    color: #6c757d;
    font-size: 0.9rem;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label {
    top: 0;
    font-size: 0.75rem;
    background-color: white;
    padding: 0 0.25rem;
}

.login-page-button {
    width: 100%;
    background: #2CB392;
    border: none;
    padding: 0.75rem;
    font-size: 1rem;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-page-button:hover {
    background: #259a7d;
}

.p-invalid .p-inputtext,
.p-invalid .p-password input {
    border-color: #E83636;
}

.p-error {
    color: #E83636;
    font-size: 0.75rem;
    margin-top: 0.25rem;
}

::placeholder {
    color: #8D8D8D;
}

.terms-text {
    font-size: 0.75rem;
    color: #8D8D8D;
    margin-top: 1rem;
    text-align: center;
    line-height: 1.5;
}

.terms-text a {
    color: #C049DE;
    text-decoration: none;
}

.terms-text a:hover {
    text-decoration: underline;
}

.forgot-password {
    margin-top: 0.5rem;
}

.forgot-password a {
    color: #32afc3;
    text-decoration: none;
    font-size: 0.8rem;
}

.forgot-password a:hover {
    text-decoration: underline;
}

.p-password {
    display: flex !important;
    align-items: center !important;
    position: relative !important;
}

.p-input-icon {
    position: absolute !important;
    right: 0.5rem !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
}

.p-password input {
    padding-right: 2.5rem !important;
    width: 100% !important;
}

/* Responsive adjustments */
@media (max-width: 480px) {
    .login-card {
        padding: 1.5rem;
    }

    .login-header h1 {
        font-size: 1.25rem;
    }
}

/* Toast styling */
.p-toast {
    z-index: 9999 !important;
}

.p-toast-message {
    margin: 0 1rem;
}

.p-toast-top-right {
    top: 20px !important;
    right: 20px !important;
    position: fixed !important;
}