.card-container {
    max-width: 900px;
    margin-left: 5%;
    border: solid 1px #2CB392;
    min-width: 450px;
    margin-right: 50px;
}

.repo-container {
    display: flex;
    flex-direction: column; /* Ensure the container allows for vertical stacking */
    align-items: center;
    padding: 10px;
}

.repo-border {
    border-bottom: 1px solid #CDCDCD;
}

.repo-info {
    flex-grow: 1;
}

.repo-actions {
    display: flex;
    justify-content: flex-end; /* Align buttons to the start */
    align-items: right;
    margin-left: auto;
}

.audit-list-title {
    font-size: 24px;
    padding-top: 10px;
}

.repositories-count {
    font-size: 16px;
    margin-bottom: 20px;
    color: #8D8D8D
}

.audit-list-empty, .audit-list-action-required {
    font-size: 24px;
    font-weight: bold;
    color: #CDCDCD;
    margin-bottom: 5px;
}


.delete-repo-from-list .p-dialog-header {
    background-color: #E83636;
}

.delete-repo-from-list .p-dialog-title {
    color: white;
    padding-left: 20px;
}

.delete-audit-button {
    margin-right: 20px;
    border: 1px solid #E83636;
    cursor: pointer;
}

.delete-audit-button:hover {
    background-color: #E83636;
    border: 1px solid #E83636;
    color: white;
}

.delete-audit-button:hover .label{
    color: white;
}

.repo-card-progress-bar {
    width: 100%;
    margin-top: 10px;
}

/* Default progress bar value color */
.repo-card-progress-bar .p-progressbar-value {
    background-color: #32AFC3;
}

/* Color for when progress is 100% */
.repo-card-progress-bar.complete .p-progressbar-value {
    background-color: #2CB392;
}

.repo-card-progress-bar .p-progressbar-label {
    color: #fff; /* Change the color */
    font-size: 10px; /* Change the font size */
}

@media (max-width: 975px) {
    .repo-container {
        flex-direction: column; /* Stack elements vertically */
        align-items: flex-start; /* Align items to the start of the container */
    }

    .repo-info, .repo-actions {
        width: 100%; /* Ensure full width is used */
    }

    .repo-actions {
        justify-content: flex-end; /* Align buttons to the start */
        margin-top: 5px;
        margin-left: -5px;
    }
}


.mobile-actions {
    display: none;
}

/* Style for the menu items */
.p-tieredmenu.p-component {
    z-index: 1000;
    min-width: 200px;
}

.p-tieredmenu .p-menuitem-link {
    padding: 0.75rem 1rem;
}

.p-tieredmenu .p-menuitem-text {
    color: #495057;
}

.p-tieredmenu .p-menuitem-icon {
    color: #495057;
    margin-right: 0.5rem;
}

/* Style for delete button hover */
.p-button.p-button-outlined.p-button-danger:hover {
    background: #E83636;
    color: white !important;
    border-color: #E83636;
}

.desktop-actions {
    display: flex;
    flex-direction: column;  /* Stack buttons vertically */
    align-items: flex-end;   /* Align buttons to the right */
    justify-content: center; /* Center vertically in the container */
}

.desktop-actions .p-button-text {
    margin-bottom: 10px;
}

.desktop-actions .p-button-outlined {
    width: 120px;  /* Match width with the Adapt Scope button */
}

@media (max-width: 768px) {
    .card-container {
        min-width: 350px;
        max-width: 100%;
    }

    .desktop-actions {
        display: none !important; /* Force hide desktop actions */
    }

    .mobile-actions {
        display: flex !important; /* Force show mobile actions */
        align-items: center;
    }

    .repo-actions {
        margin-left: auto; /* Push to the right */
    }

    .mobile-menu-button {
        padding: 0.5rem;
        margin-left: auto;
    }

    /* Ensure the burger menu is visible */
    .p-button.p-button-text.mobile-menu-button {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 560px) {
    .repo-logo {
        display: none;
    }
}


@media (max-width: 480px) {
    .card-container {
        min-width: 300px;
    }

    .repo-url-in-repo-card {
        display: none;
    }
}