.score-distribution-graph {
    width: 100%;
    padding: 1.5rem;
    background: white;
    position: relative;
    min-height: 400px;
}

.score-distribution-graph svg {
    width: 100%;
    height: 100%;
    min-height: 400px;
    overflow: visible;
}

.score-distribution-graph .grid-lines {
    z-index: 1;
}

.score-distribution-graph path {
    z-index: 2;
    position: relative;
    transition: all 0.2s ease;
}

.score-distribution-graph text {
    z-index: 3;  /* Ensure text is always on top */
}

.score-distribution-graph path:hover {
    opacity: 1;
    stroke-width: 3px;
}

.score-distribution-graph .graph-title {
    font-size: 18px;
    font-weight: 600;
    color: #323232;
}

.score-distribution-graph .graph-subtitle {
    font-size: 14px;
    color: #6c757d;
}

.score-distribution-graph .legend {
    font-size: 15px;
}

.score-distribution-graph .legend-item {
    transition: all 0.2s ease;
}

.score-distribution-graph .legend-item text {
    transition: all 0.2s ease;
    font-size: 14px;
}

.score-distribution-graph .legend-item:hover text {
    font-weight: 500;
}

.score-distribution-graph .legend-item:hover rect {
    filter: brightness(0.95);
}

.score-distribution-graph .legend rect {
    transition: all 0.2s ease;
}

.score-distribution-graph .legend text {
    transition: all 0.2s ease;
}

.score-distribution-graph .axis-label {
    font-weight: 600;
    font-size: 14px;
    color: #6c757d;
}

.score-distribution-tooltip {
    position: absolute;
    padding: 8px 12px;
    background: white;
    border-radius: 6px;
    font-size: 12px;
    color: #323232;
    pointer-events: none;
    white-space: nowrap;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e5e7eb;
    transition: opacity 0.2s ease;
}

.score-distribution-tooltip::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
    border: 6px solid transparent;
    border-top-color: white;
}

.score-distribution-graph .tooltip-line {
    transition: opacity 0.2s ease;
}

.score-distribution-graph path.highlighted {
    filter: drop-shadow(0 0 2px rgba(0,0,0,0.2));
}

.score-distribution-graph .x-axis-label {
    font-weight: 500;
}

.score-distribution-prime-tooltip.p-tooltip {
    max-width: 300px;
    z-index: 1000;
}

.score-distribution-prime-tooltip .p-tooltip-text {
    white-space: pre-line;
    font-size: 13px;
    line-height: 1.5;
    padding: 12px;
    background: white;
    color: #323232;
    border: 1px solid #e5e7eb;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    pointer-events: none;
} 

/* Responsive adjustments */
@media screen and (max-width: 992px) {
    .score-distribution-graph {
        padding: 1rem;
    }
    
    .score-distribution-graph .legend-item text {
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .score-distribution-graph {
        padding: 1rem;
    }
}


