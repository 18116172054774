/* Custom TabMenu Styles */

/* General styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;
    font-size: large;
    line-height: 1.6;
  }
  
  .p-tabmenu {
    display: flex;
    width: 100%;
  }
  
  .p-tabmenu .p-tabmenu-nav {
    background-color: transparent;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem {
    background-color: transparent;
    color: #8D8D8D;
    flex-grow: 1;
    text-align: center;
    position: relative;
  }
  
  /* Active Tab Styles */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight {
    color: #323232;
    font-weight: bold;
  }
  
  /* Underline for Active Tab */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 3px;
    background-color: #32AFC3;
    margin-top: 15px;
  }
  
  /* Non-active Tab Styles */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem::after {
    content: '';
    position: absolute;
    bottom: -4px;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #8D8D8D;
    margin-top: 15px;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .tabmenu-item-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8D8D8D;
  }
  
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem .tabmenu-item-link i {
    margin-right: 5px;
    color: #32AFC3;
  }
  
  /* Active Tab Styles */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .tabmenu-item-link {
    color: #32AFC3;
  }
  
  /* Active Tab Icon Styles */
  .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .tabmenu-item-link i {
    color: #32AFC3;
  }
  
  /* Mobile Styles */
  @media (max-width: 768px) {
    .tab-menu-container {
      margin-left: 0;
    }
  
    .p-button.p-button-icon-only {
      width: 2.357rem;
      padding: 0.5rem 0;
    }
  
    .p-menu {
      width: 100%;
      max-width: 20rem;
    }
  
    .p-menuitem-link {
      display: flex;
      align-items: center;
      padding: 0.75rem 1rem;
      color: #495057;
      transition: background-color 0.2s, box-shadow 0.2s;
    }
  
    .p-menuitem-icon {
      margin-right: 0.5rem;
    }
  
    .p-menuitem-text {
      line-height: 1;
    }
  
    .p-menuitem-link:hover {
      background-color: #e9ecef;
    }
  
    .p-menuitem-link.active-tab {
      background-color: #f8f9fa;
    }
  
    .p-menuitem-link.active-tab .p-menuitem-icon,
    .p-menuitem-link.active-tab .p-menuitem-text {
      color: #32AFC3;
    }
  
    .p-menuitem-link.active-tab .p-menuitem-text {
      padding-left: 45px;
    }
  }