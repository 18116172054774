/* Description: Styles for AuditInvitation component */
.page-layout {
  display: flex;
  padding: 20px;
  padding-left: 45px;
  gap: 20px;
  background-color: #F9F9F9;
  flex-wrap: wrap;
}

.audit-invitation-container {
  padding: 20px;
  padding-left: 45px;
  padding-right: 310px;
  display: flex;
  flex-direction: column;
  background-color: #F9F9F9;
  box-sizing: border-box;
  width: 100%;
}

.audit-invitation-content {
  margin-bottom: 30px;
}

.adding-repositories-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.repo-list-container {
  width: 100%;
}

.repository-card {
  border: solid 1px #2CB392;
  border-radius: 4px;
}

/* Adjust the width based on screen size */
@media (max-width: 1024px) {
  .audit-invitation-container {
      padding-right: 10%; /* Reduce padding for smaller screens */
      width: 100%;
  }

  .page-layout {
      padding: 15px;
  }
}

@media (max-width: 768px) {
  .audit-invitation-container {
      padding-right: 10%; /* Reduce padding for smaller screens */
      width: 100%;
  }

  .page-layout {
      padding: 10px;
  }

  .adding-repositories-container {
      flex-direction: column;
      align-items: flex-start;
  }
}

/* Further adjustments for very small screens */
@media (max-width: 480px) {
  .audit-invitation-container {
      padding-right: 10%; /* Reduce padding for smaller screens */
      width: 100%;
  }

  .page-layout {
      flex-direction: column; /* Stack elements vertically on very small screens */
      padding: 5px;
  }

  .adding-repositories-container {
      width: 100%;
  }

  .repository-card {
      width: 100%;
  }
}