/* AuditSummary.css */
/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
}

/* Ensure full height for the container and font settings */
html, body, #root, .App {
  height: 100%;
}

/* Styles for the three-column layout */
.page-layout {
  display: flex;
  flex-direction: column; /* Change the direction to column to stack children vertically */
  padding: 20px;
  padding-left: 45px;
  gap: 20px;
  background-color: #F9F9F9;
}


.overview-section, .code-review-section {
  display: flex;
  width: 100%;
  margin-left: 20px;
}

.timeline-layout {
  display: flex;
  padding: 40px 15% 0 15%;
  gap: 20px;
  background-color: #F9F9F9;
}

.left-frame {
  width: 20%;
  padding: 0 10px;
}

.middle-frame {
  width: 100%;
  padding: 0 10px;
  margin-top: 30px;
  padding-right: 10%;
}

.middle-frame-2 {
  width: 100%;
  padding: 0 10px;
  padding-right: 10%;
  margin-top: -30px;
}

.graph-frame {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 10px;
  margin-top: 30px;
  margin-bottom: 50px;
}

.main-chapter-title {
  font-size: 18px;
  font-weight: bold;
  color: #323232;
  margin-left: 20px;
  margin-top: 20px;
} 

.main-chapter-description {
  margin-top: -10px;
  margin-bottom: 30px;
}

/* Sub-chapter styles */
.sub-chapter-container {
  width: 100%;
  margin-bottom: 70px;
  margin-top: 50px;
}

/* Sub-chapter header layout - default for larger screens */
.sub-chapter-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  gap: 20px;
}

.sub-chapter-title {
  color: #C049DE;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  flex: 0 0 auto;
}

/* Score slider container for larger screens */
.score-slider-container {
  flex: 1;
  position: relative;
  padding-right: 40px;
  min-width: 200px;
}

/* Point header layout - default for larger screens */
.point-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
  padding: 0 20px;
  gap: 20px;
}

.point-title {
  font-weight: bold;
  margin-bottom: 0;
  flex: 0 0 auto;
}

.point-score-container {
  flex: 1;
  position: relative;
  padding-right: 40px;
  min-width: 200px;
}

.p-divider {
  background-color: #F9F9F9; 
  border: none;
  margin-bottom: 20px; 

}

/* Style for the divider content */
.p-divider-content {
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.chapter-divider {
  margin-bottom: 15px; /* Reduced bottom margin */
}

.sub-chapter-divider-text {
  font-weight: normal;
  margin-bottom: 15px;
  font-size: medium;
  color: #8D8D8D;
  margin-bottom: 40px;
}

.sub-chapter-divider-text strong {
  font-weight: bold;
  font-size: medium;
  color: #8D8D8D;
}

.custom-toggle-button {
  border: 1px solid #32AFC3;
  background-color: #F9F9F9;
  border-radius: 4px; /* Adjust for desired roundness */
  color: #32AFC3; /* Text color */
}

.custom-toggle-button:hover {
  background-color: #DFDFDF;
  border: 1px solid #32AFC3;
}

.custom-toggle-button.expanded {
  border-color: #8D8D8D;
  border: 1px solid #8D8D8D;
}

.p-button-label{
  font-size: 14px;
  font-weight: normal;
}

.sub-chapter-description,
.point-description {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 2rem 0;
  margin-bottom: 1rem;
  background: white;
  padding: 1rem;
  border-radius: 12px;
}

.remarks-text {
  font-weight: 600;
  color: #323232;
  margin-bottom: 4px;
}

.remarks-content {
  color: #4B4B4B;
}

/* Styles for sliders */
.score-slider {
  background-color: #E0E0E0;
  border-radius: 8px;
  position: relative;
  height: 8px;
  width: 100%;
}

.score-fill {
  background-color: #2CB392;
  height: 100%;
  border-radius: 8px 0 0 8px;
  position: absolute;
  left: 0;
  top: 0;
}

.sub-chapter .score-slider {
  min-width: 300px;
  width: 70%;
  height: 8px;
}

.points-container .score-slider {
  min-width: 200px;
  width: 50%;
  height: 6px;
}

/* Score indicator base styles */
.score-indicator {
  border-radius: 50%;
  background-color: #2CB392;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 2;
  top: 50%;
  right: -30px; /* Adjust position for larger screens */
  transform: translate(0, -50%);
}

/* Score indicator sizes */
.sub-chapter .score-indicator {
  width: 40px;
  height: 40px;
  font-size: 16px;
}

.points-container .score-indicator {
  width: 30px;
  height: 30px;
  font-size: 14px;
}

.point-item {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.score-bar {
  width: 100%;
  height: 8px;
  background-color: #E0E0E0;
  border-radius: 8px;
  position: relative;
  margin: 10px 0;
}

.avg-line {
  transition: all 0.3s ease;
}


/* Styling for completed cards */
.completedCard .p-card-body {
  border-radius: 4px;
  border: 1px solid #2CB392;
}

/* Styling for pending cards */
.pendingCard .p-card-body {
  border-radius: 4px;
  border: 1px solid #CDCDCD;
}

.p-card-title {
  font-weight: bold;
  font-size: 18px;
  color: #323232;
  padding: 10px 10px;
}

.pendingCard .p-card-title {
  color: #8D8D8D; 
}

.pendingCard .p-card-content {
  color: #8D8D8D; 
}

.p-card-content {
  font-size: 14px;
  color: #323232;
  padding: 0 10px;
  margin-bottom: 10px;
}

/* Adjust the position of cards to move them away from the central line */
.customized-timeline .p-timeline-event-content {
  transform: translateX(20px); /* Moves content to the right */
}

.customized-timeline .p-timeline-event:nth-child(even) .p-timeline-event-content {
  transform: translateX(-20px); /* Moves content to the left for alternate events */
}

/* Styling for icons */
.custom-marker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  color: white;
  border-radius: 50%;
  z-index: 1;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
}

.completedMarker {
  background-color: #2CB392;
}

.pendingMarker {
  background-color: #CDCDCD;
}

.item-description {
  color: #8D8D8D;
  font-size: 16px;
}

.audit-summary-title {
  background-color: #F9F9F9;
  color: #C049DE;
  font-size: 22px;
  font-weight: bold;
  padding-bottom: 10px;
  padding-top: 20px;
  overflow: visible;
  white-space: normal;
  user-select: none;
  text-align: center;
  margin-bottom: 0rem;
}


.audit-info-message {
  color: #8D8D8D;
  text-align: center;
  background-color: #F9F9F9;
}

.audit-email-notification {
  color: #8D8D8D;
  text-align: center;
  background-color: #F9F9F9;
}

/* Pulsating effect for the next marker */
.nextMarkerPulsating {
  animation: pulse 2s infinite;
  border: 2px solid #32afc3; /* Blue border */
  border-radius: 50%;
  padding: 5px;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(50, 175, 195, 0.7);
  }
  70% {
      box-shadow: 0 0 0 10px rgba(50, 175, 195, 0);
  }
  100% {
      box-shadow: 0 0 0 0 rgba(50, 175, 195, 0);
  }
}

/* Blue border for the next card */
.nextCardBorder {
  border: 2px solid #32afc3; /* Blue border */
  border-radius: 7px;
}

.summary-loader {width: 40px;
  height: 40px;
  --c:no-repeat linear-gradient(#32AFC3 0 0);
  background: var(--c),var(--c),var(--c),var(--c);
  background-size: 21px 21px;
  animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
  }
  
  @keyframes l5 {
  0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
  33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
  66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
  100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
  }
  
  .summary-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* High z-index to ensure it's above all other content */
  }

  .bottom-sections-container {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .bottom-sections-container section {
    margin-bottom: 30px;
  }
  
  .bottom-sections-container h2 {
    color: #323232;
    font-size: 24px;
    margin-bottom: 15px;
  }
  
  .faq details {
    margin-bottom: 15px;
  }
  
  .faq summary {
    cursor: pointer;
    font-weight: bold;
  }
  

/* Media queries for smaller screens */
@media (max-width: 768px) {
  .left-frame {
    display: none;
  }

  .middle-frame, .middle-frame-2 {
    padding-right: 20px;
    padding-left: 20px;
    width: 100%;
    max-width: 300px;
  }

  .sub-chapter-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .sub-chapter-title {
    margin-bottom: 10px;
    width: 100%;
  }

  .score-slider-container {
    width: 100%;
    padding-right: 30px;
  }

  .point-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }

  .point-title {
    margin-bottom: 10px;
    width: 100%;
  }

  .point-score-container {
    width: 100%;
    padding-right: 30px;
  }

  .point-description {
    margin: 10px;
  }

  /* Ensure score slider is visible on mobile */
  .score-slider {
    display: block;
    width: 100%;
  }

  /* Adjust score indicator position for mobile */
  .score-indicator {
    right: 0;
  }

  .sub-chapter .score-indicator {
    width: 35px;
    height: 35px;
    font-size: 14px;
  }

  .points-container .score-indicator {
    width: 25px;
    height: 25px;
    font-size: 12px;
  }

  .attribute-statistics {
    margin-left: 10px;
  }

  .point-score-container {
    padding-right: 30px;
  }
}

/* Additional adjustments for very small screens */
@media (max-width: 480px) {

  .overview-section, .code-review-section {
    margin-left: 0;
  }

  .main-chapter-description {
    max-width: 300px;
  }

  .audit-summary-panels-container {
    max-width: 300px;
  }

  .middle-frame, .middle-frame-2 {
    padding: 0 10px;
  }

  .score-slider-container,
  .point-score-container {
    padding-right: 25px;
  }

  .audit-summary-panels-container {
    max-width: 300px;
  }

  .sub-chapter-header,
  .point-header {
    gap: 10px;
    padding: 0 10px;
  }

  .score-slider {
    display: block;
    width: 100%;
  }

  .sub-chapter-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .sub-chapter-divider-text {
    max-width: 300px;
  }

  .p-divider {
    max-width: 300px;
  }

  .score-indicator {
    right: 0;
    left: 200px !important;
    top: -35px;
  }

  .sub-chapter-title {
    margin-left: -20px;
    max-width: 300px;
  }

  .sub-chapter-container {
    max-width: 300px;
  }

  .point-score-container {
    padding-right: 25px;
  }

  .audit-summary-panels-container {
    max-width: 300px;
    margin-left: -10px;
  }

  .remarks-text {
    max-width: 300px;
    margin-left: -30px;
  }

  .remarks-content {
    max-width: 300px;
    margin-left: -30px;
  }
}

/* Ensure score bars are consistently styled */
.score-bar {
  width: 100%;
  height: 8px;
  background-color: #E0E0E0;
  border-radius: 8px;
  position: relative;
  margin: 10px 0;
}

/* Remove any remaining hover effects and transitions */
.score-indicator {
  transition: none;
}