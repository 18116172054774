/* Override the global .p-inputtext styles for our specific component */
.email-input-container .improved-email-chip.email-specific-input .p-inputtext {
    height: auto !important;
    min-height: 44px !important;
    max-height: 150px !important;
    overflow-y: auto !important;
    min-width: 470px !important;
    padding: 6px !important;
  }
  
  .email-input-container .improved-email-chip.email-specific-input .p-chips-multiple-container {
    width: 100%;
    min-height: 70px;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 6px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
  }
  
  .email-input-container .improved-email-chip.email-specific-input .p-chips-token {
    background-color: #f0f0f0;
    color: #333;
    margin: 2px;
    padding: 4px 8px;
    border-radius: 16px;
    display: inline-flex;
    align-items: center;
    height: auto !important;
  }
  
  .email-input-container .improved-email-chip.email-specific-input .p-chips-token .p-chips-token-icon {
    margin-left: 6px;
    cursor: pointer;
    color: #666;
  }
  
  .email-input-container .improved-email-chip.email-specific-input .p-chips-token .p-chips-token-icon:hover {
    color: #333;
  }
  
  .email-input-container .improved-email-chip.email-specific-input .p-chips-input-token {
    padding: 4px;
    display: inline-flex;
    height: auto !important;
  }
  
  .email-input-container .improved-email-chip.email-specific-input .p-chips-input-token input {
    font-size: 14px;
    border: none;
    outline: none;
    background-color: transparent;
    height: auto !important;
    padding: 0;
    min-height: 20px !important;
  }
  
  .email-input-container .improved-email-chip.email-specific-input .p-chips-input-token input::placeholder {
    color: #999;
  }
  
  .email-input-container .custom-chip {
    display: inline-block;
    padding: 4px 8px;
    margin: 2px;
    background-color: #f0f0f0;
    border-radius: 16px;
    font-size: 14px;
  }
  
  .email-input-container .custom-chip.invalid-email {
    background-color: #ffe0e0;
    color: #d32f2f;
  }