.dependencies-page-layout {
    display: flex;
    flex-direction: column; 
    padding: 20px;
    padding-left: 45px;
    gap: 20px;
    background-color: #F9F9F9;
    justify-content: center;
    height: 100%;
  }


  .dependency-header {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 6px;
  }
  
  .dependencies-container {
    margin-left: 35px;
    margin-bottom: 40px;
    border-radius: 8px;
    border-color: #DFDFDF;
  }

  .dependency-graph {
    margin-top: 35px;
    margin-bottom: 40px;
    border-radius: 8px;
    border-color: #DFDFDF;
  }

.dependencygraph-loader {width: 40px;
height: 40px;
--c:no-repeat linear-gradient(#32AFC3 0 0);
background: var(--c),var(--c),var(--c),var(--c);
background-size: 21px 21px;
animation: l5 1.5s infinite cubic-bezier(0.3,1,0,1);
}

@keyframes l5 {
0%   {background-position: 0    0,100% 0   ,100% 100%,0 100%}
33%  {background-position: 0    0,100% 0   ,100% 100%,0 100%;width:60px;height: 60px}
66%  {background-position: 100% 0,100% 100%,0    100%,0 0   ;width:60px;height: 60px}
100% {background-position: 100% 0,100% 100%,0    100%,0 0   }
}

.dependency-loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem; /* Padding around the loader */
  width: 100%;
  height: 100%;
}

.unused-packages-warning {
  background-color: #fff7ed;
  border-left: 4px solid #f59e0b;
  padding: 1rem;
  margin: 1rem 0;
  border-radius: 4px;
}

.badge-container {
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

.badge-container .p-badge {
  font-size: 0.8rem;
  padding: 4px 8px;
}

.badge-tooltip-container {
  position: relative;
  display: inline-block;
}

.badge-tooltip {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 6px;
  font-size: 12px;
  white-space: nowrap;
}

.badge-tooltip-container:hover .badge-tooltip {
  visibility: visible;
}