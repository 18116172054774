/* Empty placeholder specific styles */
.empty-content {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}

.empty-card {
    max-width: 400px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.empty-illustration {
    margin: 20px 0;
    max-width: 200px;
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
}

.empty-message {
    color: #666;
    margin-bottom: 20px;
}

.countdown {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.days {
    font-size: 48px;
    font-weight: bold;
    color: #32AFC3;
    margin-right: 10px;
}

.days-label {
    font-size: 24px;
    color: #666;
}

.empty-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    min-width: 120px;
    max-width: 200px;
}

.empty-button.primary {
    background-color: #32AFC3;
    color: white;
}

.empty-button.primary:hover {
    background-color: #2B95A7;
    outline: none;
    box-shadow: none;
}

.empty-button:focus {
    outline: none;
}

/* Responsive styles */
@media (max-width: 480px) {
    .days {
        font-size: 36px;
    }

    .days-label {
        font-size: 20px;
    }
}

@media (max-width: 320px) {
    .days {
        font-size: 30px;
    }

    .days-label {
        font-size: 18px;
    }

    .empty-message {
        font-size: 14px;
    }
}